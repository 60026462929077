import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ReactDOM from "react-dom";
// import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./App";

import { GlobalContextProvider } from "./contexts/GlobalContext";
import { theme } from "./utils/theme";
import "./index.css";
import "rsuite/dist/rsuite.min.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n-locales/i18n-translation";
import { Web3OnboardProvider, init } from "@web3-onboard/react";
import injectedModule from "@web3-onboard/injected-wallets";

const injected = injectedModule();

const web3Onboard = init({
  wallets: [injected],
  chains: [
    {
      id: "0x1",
      token: "ETH",
      label: "Ethereum Mainnet",
      rpcUrl: "https://mainnet.infura.io/v3/cf540cb0b3b643d399e59aef4f5ac179",
    },
    {
      id: "0x3",
      token: "tROP",
      label: "Ethereum Ropsten Testnet",
      rpcUrl: "https://ropsten.infura.io/v3/cf540cb0b3b643d399e59aef4f5ac179",
    },
    {
      id: "0x4",
      token: "rETH",
      label: "Ethereum Rinkeby Testnet",
      rpcUrl: "https://rinkeby.infura.io/v3/cf540cb0b3b643d399e59aef4f5ac179",
    },
    {
      id: "0x89",
      token: "MATIC",
      label: "Matic Mainnet",
      rpcUrl: "https://matic-mainnet.chainstacklabs.com",
    },
    {
      id: "0x80001",
      token: "MATIC",
      label: "Matic Testnet",
      rpcUrl:
        "https://polygon-mumbai.infura.io/v3/df21e131c9b5486ea9ad976d9d3487da",
    },
  ],
  appMetadata: {
    name: "IP Management Platform",
    icon: "<svg><svg/>",
    description: "Demo app for IP Management Platform",
    recommendedInjectedWallets: [
      { name: "MetaMask", url: "https://metamask.io/download.html" },
    ],
  },
  i18n: {
    en: {
      connect: {
        connectedWallet: {
          header: "Connection Successful",
          sidebar: {
            subheading: "Connection Successful!",
            paragraph: "Your wallet is now connected to IP Management Platform",
          },
        },
      },
    },
  },
});

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//   <GlobalContextProvider>
//     <Web3OnboardProvider web3Onboard={web3Onboard}>
//       <I18nextProvider i18n={i18n}>
//         <Router>
//           <ThemeProvider theme={theme}>
//             <CssBaseline />
//             <App {...window.xprops} />
//           </ThemeProvider>
//         </Router>
//       </I18nextProvider>
//     </Web3OnboardProvider>
//   </GlobalContextProvider>
//   </React.StrictMode>
// );

ReactDOM.render(
  <React.StrictMode>
    <GlobalContextProvider>
      <Web3OnboardProvider web3Onboard={web3Onboard}>
        <I18nextProvider i18n={i18n}>
          <Router>
            <ThemeProvider theme={theme}>
              <DndProvider backend={HTML5Backend}>
                <CssBaseline />
                <App {...window.xprops} />
              </DndProvider>
            </ThemeProvider>
          </Router>
        </I18nextProvider>
      </Web3OnboardProvider>
    </GlobalContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
