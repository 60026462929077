import {
  RiFolderOpenLine,
  RiGalleryLine,
  RiParkingBoxLine,
  RiDashboard3Line,
  RiGroupLine,
  RiHeartLine,
  RiSettings3Line,
  RiChat1Line,
} from "react-icons/ri";

const enableFeature = process.env.REACT_APP_HIDE_NFT_FEATURE === "true";

export const GLOBAL_ROUTES = new Map([
  [
    "HOME",
    {
      ID: "_home",
      LABEL: "Home",
      PATH: "/",
      VALUE: 0,
    },
  ],
  [
    "SEARCH",
    {
      ID: "_search",
      LABEL: "Search",
      PATH: "/search/:query",
      PATHNAME: "/search",
      VALUE: 1,
    },
  ],
  [
    "PROJECT",
    {
      ID: "_project",
      LABEL: "Project",
      PATH: "/project/:projectId",
      PATHNAME: "/project",
      VALUE: 2,
    },
  ],
  [
    "INSTITUTE",
    {
      ID: "_institute",
      LABEL: "Institute",
      PATH: "/institute/:institute",
      PATHNAME: "/institute",
      VALUE: 3,
    },
  ],
  [
    "INSTITUTIONS",
    {
      ID: "_institutions",
      LABEL: "Institutions",
      PATH: "/institutions",
      PATHNAME: "/institutions",
      VALUE: "institutions",
    },
  ],
  [
    "MARKETPLACE",
    {
      ID: "_marketplace",
      LABEL: "Marketplace",
      PATH: "/marketplace/:query",
      PATHNAME: "/marketplace",
      VALUE: 4,
    },
  ],
  [
    "NFT",
    {
      ID: "_nft",
      LABEL: "NFT",
      PATH: "/nft/:nftId/:contractAddr",
      PATHNAME: "/nft",
      VALUE: 5,
    },
  ],
  [
    "PAGE_NOT_FOUND",
    {
      ID: "_page_not_found",
      LABEL: "",
      PATH: "/page-not-found",
      VALUE: null,
    },
  ],
  [
    "PATENT_POOL_MARKETPLACE",
    {
      ID: "_patent_pool_marketplace",
      LABEL: "Patent Pool",
      PATH: "/patent-pool-marketplace/:query",
      PATHNAME: "/patent-pool-marketplace",
      VALUE: 5,
    },
  ],
  [
    "MY_PATENT_POOL",
    {
      ID: "my_patent_pool",
      LABEL: "Patent Pool",
      PATH: "/my-patent-pool",
      PATHNAME: "/my-patent-pool",
      VALUE: 6,
    },
  ],
  [
    "PATENT_POOL",
    {
      ID: "patent_pool",
      LABEL: "Patent Pool",
      PATH: "/patent-pool/:poolId/:contractAddr",
      PATHNAME: "/patent-pool",
      VALUE: 7,
    },
  ],
  [
    "DELETE_SUCCESS",
    {
      ID: "_delete_success",
      LABEL: "",
      PATH: "/delete-success",
      VALUE: null,
    },
  ],
  [
    "SUBMIT_SUCCESS",
    {
      ID: "_submit_success",
      LABEL: "",
      PATH: "/submit-success",
      VALUE: null,
    },
  ],
  [
    "CHATBOT",
    {
      ID: "_chatbot",
      LABEL: "IP8Chat",
      PATH: "/chatbot",
      PATHNAME: "/chatbot",
      VALUE: null,
    },
  ],
  [
    "CHATBOT_TEMP",
    {
      ID: "_chatbot_temp",
      LABEL: "IP8Chat",
      PATH: "/chatbot-nus",
      PATHNAME: "/chatbot-nus",
      VALUE: null,
    },
  ],
  [
    "DEMAND_PORTAL",
    {
      ID: "_demand_portal",
      LABEL: "Demand Portal",
      PATH: "/demand-portal",
      PATHNAME: "/demand-portal",
      VALUE: null,
    },
  ],
  [
    "DEMAND_DETAIL",
    {
      ID: "demand_detail",
      LABEL: "Demand Detail",
      PATH: "/demand-detail/:demandId",
      PATHNAME: "/demand-detail",
      VALUE: null,
    },
  ],
]);

export const PUBLIC_ROUTES = new Map([
  [
    "LOGIN",
    {
      ID: "_login",
      LABEL: "Login",
      PATH: "/login",
      VALUE: 0,
    },
  ],
  [
    "SIGNUP",
    {
      ID: "_signup",
      LABEL: "Signup",
      PATH: "/signup",
      VALUE: 1,
    },
  ],
  [
    "RESET_PASSWORD",
    {
      ID: "_reset_password",
      LABEL: "Reset Password",
      PATH: "/reset-password",
      VALUE: 2,
    },
  ],
]);

export const PRIVATE_ROUTES = new Map([
  [
    "FAVOURITES",
    {
      ID: "_favourites",
      LABEL: "Favourites",
      PATH: "/favourites",
      VALUE: 0,
    },
  ],
  [
    "POSTS",
    {
      ICON: <RiFolderOpenLine size="1.2em" />,
      ID: "posts",
      LABEL: "Posts",
      PATH: "/posts",
      VALUE: 1,
    },
  ],
  [
    "LIKED_POSTS",
    {
      ICON: <RiHeartLine size="1.2em" />,
      ID: "_liked_posts",
      LABEL: "Bookmarks",
      PATH: "/liked-posts",
    },
  ],
  [
    "USERS",
    {
      ICON: <RiGroupLine size="1.2em" />,
      ID: "_users",
      LABEL: "Users",
      PATH: "/users",
      VALUE: 2,
    },
  ],
  [
    "ACCOUNT",
    {
      ICON: <RiSettings3Line size="1.2em" />,
      ID: "_account",
      LABEL: "Settings",
      PATH: "/account",
      VALUE: 3,
    },
  ],
  [
    "EDITOR",
    {
      ID: "_editor",
      LABEL: "Editor",
      PATH: "/editor",
      VALUE: 4,
    },
  ],
  [
    "UPDATE_EDITOR",
    {
      ID: "_update_editor",
      LABEL: "Editor",
      PATH: "/editor/:projectId",
      PATHNAME: "/editor",
      VALUE: 5,
    },
  ],
  // [
  //   "RESET_PASSWORD",
  //   {
  //     ID: "_reset_password",
  //     LABEL: "Reset Password",
  //     PATH: "/reset-password",
  //     VALUE: 6,
  //   },
  // ],
  [
    "CONNECT_WALLET",
    {
      ID: "_connect_wallet",
      LABEL: "Connect Wallet",
      PATH: "/connect-wallet",
      VALUE: 7,
    },
  ],
  [
    "CONNECT_WALLET_FROM_PROJECT",
    {
      ID: "_connect_wallet_from_project",
      LABEL: "Connect Wallet",
      PATH: "/connect-wallet/:projectId",
      PATHNAME: "/connect-wallet",
      VALUE: 8,
    },
  ],
  [
    "CREATE_NFT",
    {
      ID: "_create_nft",
      LABEL: "Create Nft",
      PATH: "/create-nft",
      VALUE: 9,
    },
  ],
  [
    "CREATE_NFT_FROM_PROJECT",
    {
      ID: "_create_nft_from_project",
      LABEL: "Create Nft",
      PATH: "/create-nft/:projectId",
      PATHNAME: "/create-nft",
      VALUE: 10,
    },
  ],
  [
    "My_NFTs",
    {
      ID: "_my_nft",
      LABEL: enableFeature ? "My Tokens" : "My NFTs",
      PATH: "/my-nft",
      VALUE: 11,
      ICON: <RiGalleryLine size="1.2em" />,
    },
  ],
  [
    "USER_NOTIFICATION",
    {
      ID: "_user_notification",
      LABEL: "User Notification",
      PATH: "/user_notification",
      VALUE: 12,
    },
  ],
  [
    "LIST_NFT",
    {
      ID: "_list_nft",
      LABEL: "List NFT",
      PATH: "/list-nft/:nftId/:projectId/:contractAddr",
      PATHNAME: "/list-nft",
      VALUE: 13,
    },
  ],
  [
    "CREATE_PATENT_POOL",
    {
      ID: "_create_patent_pool",
      LABEL: "Create Patent Pool",
      PATH: "/create-patent-pool",
      VALUE: 14,
    },
  ],
  [
    "MY_PATENT_POOL",
    {
      ID: "_my_patent_pool",
      LABEL: "Patent Pools",
      PATH: "/my-patent-pool",
      VALUE: 15,
      ICON: <RiParkingBoxLine size="1.2em" />,
    },
  ],
  [
    "EDIT_PATENT_POOL",
    {
      ID: "patent_pool_edit",
      LABEL: "Patent Pool Edit",
      PATH: "/patent-pool-edit/:poolId/:contractAddr",
      PATHNAME: "/patent-pool-edit",
      VALUE: 16,
    },
  ],
  [
    "DASHBOARD",
    {
      ID: "_dashboard",
      LABEL: "Dashboard",
      PATH: "/dashboard",
      VALUE: 17,
      ICON: <RiDashboard3Line size="1.2em" />,
    },
  ],
  [
    "DASHBOARD_DUMMY",
    {
      ID: "_dashboard",
      LABEL: "Dashboard",
      PATH: "/dashboard_dummy_data",
      VALUE: 18,
      ICON: <RiDashboard3Line size="1.2em" />,
    },
  ],
  [
    "CREATE_DEMAND",
    {
      ID: "demand_create",
      LABEL: "Create Demand",
      PATH: "/demand-create",
      VALUE: 19,
    },
  ],
  [
    "EDIT_DEMAND",
    {
      ID: "demand_edit",
      LABEL: "Edit Demand",
      PATH: "/demand-edit/:demandId",
      PATHNAME: "/demand-edit",
      VALUE: 20,
    },
  ],
  [
    "MY_DEMANDS",
    {
      ID: "my_demands",
      LABEL: "My Demands",
      PATH: "/my-demands",
      VALUE: 21,
      ICON: <RiChat1Line size="1.2em" />,
    },
  ],

  [
    "API_TEST",
    {
      ID: "_api_test",
      LABEL: "API TEST",
      PATH: "/api-test",
      PATHNAME: "/api-test",
      VALUE: 22,
    },
  ],
]);
