import { appBar, theme } from "../../../../utils/theme";

export const styles = {
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  margin: {
    display: "flex",
    margin: 1,
  },
  nav: {
    alignItems: "center",
    backgroundColor: "rgba(255,255,255,0.78)",
    backdropFilter: "saturate(180%) blur(20px)",
    borderBottomColor: theme.palette.grays[300],
    borderBottomWidth: 0,
    borderBottomStyle: "solid",
    boxShadow: "1px 1px 2px rgba(0, 0, 0, 0.08)",
    display: "flex",
    transition: "all 0.05s ease-in-out",
  },
  toolbar: {
    color: theme.palette.grays[800],
    height: appBar.height,
    maxWidth: appBar.maxWidth,
    pb: 0,
    pl: `0 !important`,
    pr: `0 !important`,
    pt: 0,
    width: appBar.width,
  },
};
