import {
  LOGIN_TOKEN,
  apiUrl,
  extractErrorMessage,
  handleLoginTokenExpired,
  loginToken,
  prepareFormData,
  removeToken,
  storeToken,
} from "../utils/apiUtils";

export const registerUser = async (data, dispatch) => {
  const formData = prepareFormData(data);

  try {
    const response = await fetch(`${apiUrl}api/user/public`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: formData,
    });

    const jsonData = await response.json();

    if (!jsonData.log) {
      return { data: jsonData, status: "resolved" };
    } else {
      throw jsonData.log;
    }
  } catch (err) {
    console.error(`ERROR ${err}`);

    return { data: extractErrorMessage(err), status: "rejected" };
  }
};

export const loginUser = async (data, dispatch) => {
  const formData = prepareFormData(data);

  try {
    const response = await fetch(`${apiUrl}api/user/action/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: formData,
    });

    const jsonData = await response.json();

    if (jsonData.token !== "") {
      storeToken(LOGIN_TOKEN, jsonData.token);

      dispatch({
        type: "SHOW_TOAST",
        payload: {
          action: undefined,
          message: "You have been logged in",
          isOpen: true,
        },
      });

      return { data: jsonData, status: "resolved" };
    } else {
      throw jsonData.log;
    }
  } catch (err) {
    console.error(`ERROR ${err}`);

    return { data: extractErrorMessage(err), status: "rejected" };
  }
};

export const deleteUser = async (data, dispatch) => {
  const formData = prepareFormData(data);

  try {
    const response = await fetch(`${apiUrl}api/user/`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${loginToken()}`,
        "Content-Type": "application/json",
      },
      body: formData,
    });

    const jsonData = await response.json();

    if (!jsonData.log) {
      return { data: jsonData, status: "resolved" };
    } else {
      throw jsonData.log;
    }
  } catch (err) {
    console.error(`ERROR ${err}`);

    return { data: extractErrorMessage(err), status: "rejected" };
  }
};

// Send change password PIN to user email
export const sendChangePasswordPinToUserEmail = async (data, dispatch) => {
  const formData = prepareFormData({
    email: data,
  });

  try {
    const response = await fetch(`${apiUrl}api/user/action/forget`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: formData,
    });

    const jsonData = await response.json();

    if (!jsonData.log) {
      return { data: jsonData, status: "resolved" };
    } else {
      throw jsonData.log;
    }
  } catch (err) {
    console.error(`ERROR ${err}`);

    dispatch({
      type: "SHOW_TOAST",
      payload: {
        action: undefined,
        message:
          `${err}` || "Sorry, we could not find your email in our system.",
        isOpen: true,
      },
    });

    return { data: err, status: "rejected" };
  }
};

// Reset password
export const resetPassword = async (email, pin, password, dispatch) => {
  const dataObj = {
    email: email,
    PIN: pin,
    password: password,
  };
  const formData = prepareFormData(dataObj);

  try {
    const response = await fetch(`${apiUrl}api/user/action/reset`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: formData,
    });

    const jsonData = await response.json();

    if (!jsonData.log) {
      return { data: jsonData, status: "resolved" };
    } else {
      throw jsonData.log;
    }
  } catch (err) {
    console.error(`ERROR ${err}`);

    dispatch({
      type: "SHOW_TOAST",
      payload: {
        action: undefined,
        message: err.details
          ? `${err.details}`
          : "Unable to reset password. Please try again.",
        isOpen: true,
      },
    });

    return { data: err, status: "rejected" };
  }
};

export const retrieveUserProfile = async (dispatch, navigate) => {
  try {
    const response = await fetch(`${apiUrl}api/user/`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loginToken()}`,
        "Content-Type": "application/json",
      },
    });

    const jsonData = await response.json();

    if (jsonData.hasOwnProperty("log")) {
      removeToken(LOGIN_TOKEN);

      throw jsonData.log;
    } else {
      dispatch({
        type: "STORE_USER_PROFILE",
        payload: jsonData,
      });

      return jsonData;
    }
  } catch (err) {
    // console.error(`ERROR ${err}`);
  }
};

export const updateUserProfile = async (data, dispatch, navigate) => {
  const { areas, ...processedData } = data;
  const formData = prepareFormData(processedData);

  try {
    const response = await fetch(`${apiUrl}api/user/`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${loginToken()}`,
        "Content-Type": "application/json",
      },
      body: formData,
    });

    const jsonData = await response.json();

    if (!jsonData.log) {
      return { data: jsonData, status: "resolved" };
    } else {
      throw jsonData.log;
    }
  } catch (err) {
    console.error(`ERROR ${err}`);

    if (
      err.includes("Token is expired") ||
      err.includes("token contains an invalid number of segments")
    ) {
      handleLoginTokenExpired(dispatch, navigate);
    }

    return { data: err, status: "rejected" };
  }
};

export const updateUserPassword = async (data, dispatch, navigate) => {
  const formData = prepareFormData(data);

  try {
    const response = await fetch(`${apiUrl}api/user/action/change`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loginToken()}`,
        "Content-Type": "application/json",
      },
      body: formData,
    });

    const jsonData = await response.json();

    if (!jsonData.log) {
      return { data: jsonData, status: "resolved" };
    } else {
      throw jsonData.log;
    }
  } catch (err) {
    console.error(`ERROR ${err}`);

    if (
      err.includes("Token is expired") ||
      err.includes("token contains an invalid number of segments")
    ) {
      handleLoginTokenExpired(dispatch, navigate);
    }

    return { data: err, status: "rejected" };
  }
};

export const retrieveUserPosts = async (data, dispatch, navigate, signal) => {
  try {
    const response = await fetch(`${apiUrl}api/ip/get_user_owned_ip`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loginToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      signal,
    });

    const jsonData = await response.json();

    if (jsonData.hasOwnProperty("log")) {
      throw jsonData.log;
    } else {
      return jsonData;
    }
  } catch (err) {
    console.error(`ERROR ${err}`);

    if (
      err.includes("Token is expired") ||
      err.includes("token contains an invalid number of segments")
    ) {
      handleLoginTokenExpired(dispatch, navigate);
    }

    dispatch({
      type: "SHOW_TOAST",
      payload: {
        action: undefined,
        message: extractErrorMessage(err),
        isOpen: true,
      },
    });
  }
};

// Get all institute inventors (admin).
export const retrieveInstituteInventors = async (dispatch, navigate) => {
  try {
    const response = await fetch(`${apiUrl}api/user/inventor`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loginToken()}`,
        "Content-Type": "application/json",
      },
    });

    const jsonData = await response.json();

    if (jsonData.hasOwnProperty("log")) {
      throw jsonData.log;
    } else {
      return { data: jsonData, status: "resolved" };
    }
  } catch (err) {
    console.error(`ERROR ${err}`);

    if (
      err.includes("Token is expired") ||
      err.includes("token contains an invalid number of segments")
    ) {
      handleLoginTokenExpired(dispatch, navigate);
    }

    return { data: err, status: "rejected" };
  }
};

// Get all institute managers (admin)
export const retrieveInstituteManagers = async (dispatch, navigate) => {
  try {
    const response = await fetch(`${apiUrl}api/user/manager`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loginToken()}`,
        "Content-Type": "application/json",
      },
    });

    const jsonData = await response.json();

    if (jsonData.hasOwnProperty("log")) {
      throw jsonData.log;
    } else {
      return { data: jsonData, status: "resolved" };
    }
  } catch (err) {
    console.error(`ERROR ${err}`);

    if (
      err.includes("Token is expired") ||
      err.includes("token contains an invalid number of segments")
    ) {
      handleLoginTokenExpired(dispatch, navigate);
    }

    return { data: err, status: "rejected" };
  }
};

// Add institute inventors (admin)
export const addInstituteInventors = async (data, dispatch, navigate) => {
  const formData = prepareFormData(data);

  try {
    const response = await fetch(`${apiUrl}api/user/inventor`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loginToken()}`,
        "Content-Type": "application/json",
      },
      body: formData,
    });

    const jsonData = await response.json();

    if (!jsonData.log) {
      return { data: jsonData, status: "resolved" };
    } else {
      throw jsonData.log;
    }
  } catch (err) {
    console.error(`ERROR ${err}`);

    if (
      err.includes("Token is expired") ||
      err.includes("token contains an invalid number of segments")
    ) {
      handleLoginTokenExpired(dispatch, navigate);
    }

    return { data: err, status: "rejected" };
  }
};

// Add institute managers (admin)
export const addInstituteManagers = async (data, dispatch, navigate) => {
  const formData = prepareFormData(data);

  try {
    const response = await fetch(`${apiUrl}api/user/manager`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loginToken()}`,
        "Content-Type": "application/json",
      },
      body: formData,
    });

    const jsonData = await response.json();

    if (!jsonData.log) {
      return { data: jsonData, status: "resolved" };
    } else {
      throw jsonData.log;
    }
  } catch (err) {
    console.error(`ERROR ${err}`);

    if (
      err.includes("Token is expired") ||
      err.includes("token contains an invalid number of segments")
    ) {
      handleLoginTokenExpired(dispatch, navigate);
    }

    return { data: err, status: "rejected" };
  }
};

// Remove institute inventors (admin)
export const removeInstituteInventors = async (data, dispatch, navigate) => {
  const formData = prepareFormData({
    email: data.email,
  });

  try {
    const response = await fetch(`${apiUrl}api/user/inventor`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${loginToken()}`,
        "Content-Type": "application/json",
      },
      body: formData,
    });

    const jsonData = await response.json();

    if (!jsonData.log) {
      return { data: jsonData, status: "resolved" };
    } else {
      throw jsonData.log;
    }
  } catch (err) {
    console.error(`ERROR ${err}`);

    if (
      err.includes("Token is expired") ||
      err.includes("token contains an invalid number of segments")
    ) {
      handleLoginTokenExpired(dispatch, navigate);
    }

    return { data: err, status: "rejected" };
  }
};

// Remove institute managers (admin)
export const removeInstituteManagers = async (data, dispatch, navigate) => {
  const formData = prepareFormData({
    email: data.email,
  });

  try {
    const response = await fetch(`${apiUrl}api/user/manager`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${loginToken()}`,
        "Content-Type": "application/json",
      },
      body: formData,
    });

    const jsonData = await response.json();

    if (!jsonData.log) {
      return { data: jsonData, status: "resolved" };
    } else {
      throw jsonData.log;
    }
  } catch (err) {
    console.error(`ERROR ${err}`);

    if (
      err.includes("Token is expired") ||
      err.includes("token contains an invalid number of segments")
    ) {
      handleLoginTokenExpired(dispatch, navigate);
    }

    return { data: err, status: "rejected" };
  }
};

// Add post to list of favourites for current user
export const addFavouriteIp = async (id, dispatch, navigate) => {
  const formData = prepareFormData({
    pid: id,
  });

  try {
    const response = await fetch(`${apiUrl}api/like/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loginToken()}`,
      },
      body: formData,
    });

    const jsonData = await response.json();

    if (!jsonData.log) {
      return { data: jsonData, status: "resolved" };
    } else {
      throw jsonData.log;
    }
  } catch (err) {
    console.error(`ERROR ${err}`);

    if (
      err.includes("Token is expired") ||
      err.includes("token contains an invalid number of segments")
    ) {
      handleLoginTokenExpired(dispatch, navigate);
    }

    return { data: extractErrorMessage(err), status: "rejected" };
  }
};

// Remove post from list of favourites for current user
export const removeFavouriteIp = async (id, dispatch, navigate) => {
  const formData = prepareFormData({
    pid: id,
  });

  try {
    const response = await fetch(`${apiUrl}api/like/`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${loginToken()}`,
      },
      body: formData,
    });

    const jsonData = await response.json();

    if (!jsonData.log) {
      return { data: jsonData, status: "resolved" };
    } else {
      throw jsonData.log;
    }
  } catch (err) {
    console.error(`ERROR ${err}`);

    if (
      err.includes("Token is expired") ||
      err.includes("token contains an invalid number of segments")
    ) {
      handleLoginTokenExpired(dispatch, navigate);
    }

    return { data: extractErrorMessage(err), status: "rejected" };
  }
};

// Fetch all favourite posts for current user
export const retrieveFavouriteIp = async (dispatch, navigate) => {
  try {
    const response = await fetch(`${apiUrl}api/like/`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loginToken()}`,
        "Content-Type": "application/json",
      },
    });

    const jsonData = await response.json();

    if (jsonData.hasOwnProperty("log")) {
      throw jsonData.log;
    } else {
      return { data: jsonData, status: "resolved" };
    }
  } catch (err) {
    console.error(`ERROR ${err}`);

    if (
      err.includes("Token is expired") ||
      err.includes("token contains an invalid number of segments")
    ) {
      handleLoginTokenExpired(dispatch, navigate);
    }

    return { data: err, status: "rejected" };
  }
};

/** Update Institute Information (Admin) */
export const updateInstituteInformation = async (data, dispatch, navigate) => {
  const formData = {
    info: data,
  };

  try {
    const response = await fetch(`${apiUrl}api/institute/`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${loginToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    const jsonData = await response.json();

    if (jsonData.hasOwnProperty("log")) {
      throw jsonData.log;
    } else {
      return { data: jsonData, status: "resolved" };
    }
  } catch (err) {
    console.error(`ERROR ${err}`);

    if (
      err.includes("Token is expired") ||
      err.includes("token contains an invalid number of segments")
    ) {
      handleLoginTokenExpired(dispatch, navigate);
    }

    /** !!!TODO: Revise backend error message via frontend currently.
     * To retrieve error code & message from backend */
    let errorMessage = "";

    if (
      String(err).includes(
        "code: <500>, error message: <exceed the maximum number of top posts per institute>"
      )
    ) {
      errorMessage = "You have exceeded the maximum number of top posts.";
    } else {
      errorMessage = String(err);
    }

    return { data: errorMessage, status: "rejected" };
  }
};
