import { apiUrl, loginToken, prepareFormData } from "../utils/apiUtils";

export const retrieveIpHash = async (id) => {
  const token = loginToken();
  const method =
    token !== null
      ? {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {
          method: "GET",
        };

  try {
    const response = await fetch(`${apiUrl}api/nft/hash?pid=${id}`, method);
    if (response.status === 200) {
      const jsonData = await response.json();

      if (jsonData.hasOwnProperty("log")) {
        throw jsonData.log;
      } else {
        return jsonData;
      }
    }

    return undefined;
  } catch (err) {
    console.error(`ERROR ${err}`);
  }
};

// export const retrieveNftMetadata = async (data) => {
//   try {
//     const resp = await fetch(data.tokenUri.gateway);

//     if (resp.status === 200) {
//       const jsonData = await resp.json();

//       const mergedData = { ...jsonData, ...data };
//       mergedData["image"] = `https://ipfs.io/ipfs/${jsonData.image.replace(
//         "ipfs://",
//         ""
//       )}`;

//       // //!!!REMOVE START: dummy prices
//       // const dummyObj = { ...mergedData };

//       // dummyObj["price"] =
//       //   Math.floor(Math.random() * (10 * 100 - 1 * 100) + 1 * 100) / (1 * 100);
//       // dummyObj["lastSale"] =
//       //   Math.floor(Math.random() * (10 * 100 - 1 * 100) + 1 * 100) / (1 * 100);
//       // //!!!REMOVE END: dummy prices

//       // return dummyObj;
//       return mergedData;
//     } else {
//       throw new Error(resp);
//     }
//   } catch (err) {}
// };

export const retrieveNftMetadataFromTokenUri = async (tokenUri) => {
  try {
    const resp = await fetch(tokenUri);

    if (resp.status === 200) {
      const jsonData = await resp.json();
      jsonData["image"] = `https://ipfs.io/ipfs/${jsonData.image.replace(
        "ipfs://",
        ""
      )}`;

      // //!!!REMOVE START: dummy prices
      // const dummyObj = { ...mergedData };

      // dummyObj["price"] =
      //   Math.floor(Math.random() * (10 * 100 - 1 * 100) + 1 * 100) / (1 * 100);
      // dummyObj["lastSale"] =
      //   Math.floor(Math.random() * (10 * 100 - 1 * 100) + 1 * 100) / (1 * 100);
      // //!!!REMOVE END: dummy prices

      // return dummyObj;
      return jsonData;
    } else {
      throw new Error(resp);
    }
  } catch (err) {}
};

export const searchNFT = async (string) => {
  const formData = prepareFormData({ text: `${string}` });

  try {
    const response = await fetch(`${apiUrl}api/search/nft`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: formData,
    });

    if (response.status === 200) {
      const jsonData = await response.json();

      if (jsonData.hasOwnProperty("log")) {
        throw jsonData.log;
      } else {
        return jsonData;
      }
    }

    return undefined;
  } catch (err) {
    console.error(`ERROR ${err}`);
  }
};

export const transformNFT = async (data, pid) => {
  const formData = `{"content":${prepareFormData(data)},"pid":"${pid}"}`;
  try {
    const response = await fetch(`${apiUrl}api/nft/trans`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loginToken()}`,
        "Content-Type": "application/json",
      },
      body: formData,
    });
    if (response.status === 200) {
      const jsonData = await response.json();
      if (jsonData.log === "") {
        return { data: jsonData, status: "resolved" };
      } else {
        throw jsonData.log;
      }
    } else {
      throw new Error("Request failed");
    }
  } catch (err) {
    return { data: err, status: "rejected" };
  }
};

export const updateWallet = async (walletAddress) => {
  try {
    const response = await fetch(
      `${apiUrl}api/nft/wallet?wallet=${walletAddress}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${loginToken()}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      const jsonData = await response.json();
      if (jsonData.log === "") {
        return { data: jsonData, status: "resolved" };
      } else {
        throw jsonData.log;
      }
    }
  } catch (err) {
    console.error(`ERROR ${err}`);
  }
};

// Retrieve nfts by nft id
export const retrieveNftById = async (data) => {
  const formData = `{"nid":${JSON.stringify(data)}}`;
  try {
    const response = await fetch(`${apiUrl}api/nft/get`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loginToken()}`,
        "Content-Type": "application/json",
      },
      body: formData,
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      if (jsonData.hasOwnProperty("log")) {
        throw jsonData.log;
      } else {
        return jsonData;
      }
    }
  } catch (err) {
    console.error(`ERROR ${err}`);
  }
};

// Delete nfts by nft id
export const deleteNftById = async (data) => {
  const formData = `{"nid":${JSON.stringify(data)}}`;
  try {
    const response = await fetch(`${apiUrl}api/nft/`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${loginToken()}`,
        "Content-Type": "application/json",
      },
      body: formData,
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      if (jsonData.log === "") {
        return { data: jsonData, status: "resolved" };
      } else {
        throw jsonData.log;
      }
    }
  } catch (err) {
    console.error(`ERROR ${err}`);
  }
};

// Retrieve nfts owned by current user
export const retrieveOwnedNft = async () => {
  try {
    const response = await fetch(`${apiUrl}api/nft/get_owned`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loginToken()}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      if (jsonData.hasOwnProperty("log")) {
        throw jsonData.log;
      } else {
        return jsonData;
      }
    }
  } catch (err) {
    console.error(`ERROR ${err}`);
  }
};

// Retrieve nfts owned by current user
export const retrieveAllNft = async () => {
  try {
    const response = await fetch(`${apiUrl}api/nft/get_all`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loginToken()}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      if (jsonData.hasOwnProperty("log")) {
        throw jsonData.log;
      } else {
        return jsonData;
      }
    }
  } catch (err) {
    console.error(`ERROR ${err}`);
    throw err;
  }
};

// Update NFT
export const updateNFT = async (data) => {
  const formData = `{"content":${prepareFormData(data)}}`;
  try {
    const response = await fetch(`${apiUrl}api/nft/update`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loginToken()}`,
        "Content-Type": "application/json",
      },
      body: formData,
    });
    if (response.status === 200) {
      const jsonData = await response.json();
      if (jsonData.log === "") {
        return { data: jsonData, status: "resolved" };
      } else {
        throw jsonData.log;
      }
    }
  } catch (err) {
    console.error(`ERROR ${err}`);
  }
};

// Update an NFT price and make it onsell.
export const updateNftPrice = async (nid, price) => {
  const formData = `{"nid":${JSON.stringify(nid)},"price":${JSON.stringify(
    price
  )}}`;
  try {
    const response = await fetch(`${apiUrl}api/nft/update_price`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loginToken()}`,
        "Content-Type": "application/json",
      },
      body: formData,
    });
    if (response.status === 200) {
      const jsonData = await response.json();
      if (jsonData.log === "") {
        return { data: jsonData, status: "resolved" };
      } else {
        throw jsonData.log;
      }
    }
  } catch (err) {
    console.error(`ERROR ${err}`);
  }
};

// Unlist an NFT price and make it unsell.
export const unlistNft = async (nid) => {
  const formData = `{"nid":${JSON.stringify(nid)}}`;
  try {
    const response = await fetch(`${apiUrl}api/nft/unlist`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loginToken()}`,
        "Content-Type": "application/json",
      },
      body: formData,
    });
    if (response.status === 200) {
      const jsonData = await response.json();
      if (jsonData.log === "") {
        return { data: jsonData, status: "resolved" };
      } else {
        throw jsonData.log;
      }
    }
  } catch (err) {
    console.error(`ERROR ${err}`);
  }
};

// Synchronize the NFT purchase record.
export const updateNftPurchaseRecord = async (nid, evidence) => {
  const formData = `{"nid":${JSON.stringify(nid)},"evidence":${JSON.stringify(
    evidence
  )}}`;
  try {
    const response = await fetch(`${apiUrl}api/nft/sync_buy`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loginToken()}`,
        "Content-Type": "application/json",
      },
      body: formData,
    });
    if (response.status === 200) {
      const jsonData = await response.json();
      if (jsonData.hasOwnProperty("log")) {
        throw jsonData.log;
      } else {
        return jsonData;
      }
    }
  } catch (err) {
    console.error(`ERROR ${err}`);
  }
};

// Get the nft purchased by current user
export const getPurchasedNft = async () => {
  try {
    const response = await fetch(`${apiUrl}api/nft/get_purchased`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loginToken()}`,
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      const jsonData = await response.json();
      if (jsonData.hasOwnProperty("log")) {
        throw jsonData.log;
      } else {
        return jsonData;
      }
    }
  } catch (err) {
    console.error(`ERROR ${err}`);
  }
};

// Get all nft history of current user.
export const getAllNftHistory = async () => {
  try {
    const response = await fetch(`${apiUrl}api/nft/get_hist`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loginToken()}`,
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      const jsonData = await response.json();
      if (jsonData.hasOwnProperty("log")) {
        throw jsonData.log;
      } else {
        return jsonData;
      }
    }
  } catch (err) {
    console.error(`ERROR ${err}`);
  }
};

// Update the purchase agreement of NFT.
export const updateNftAgreement = async (nid, purchaseAgreeStatus) => {
  const formData = `{"nid":${JSON.stringify(nid)},"need_agree":${JSON.stringify(
    purchaseAgreeStatus
  )}}`;
  try {
    const response = await fetch(`${apiUrl}api/nft/update_agree`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loginToken()}`,
        "Content-Type": "application/json",
      },
      body: formData,
    });
    if (response.status === 200) {
      const jsonData = await response.json();
      if (jsonData.log === "") {
        return { data: jsonData, status: "resolved" };
      } else {
        throw jsonData.log;
      }
    }
  } catch (err) {
    console.error(`ERROR ${err}`);
  }
};

// Get the purchase requests for a NFT.
export const getNftPurchaseRequest = async (nftID, state) => {
  const formData = `{"nftID":${JSON.stringify(nftID)},"state":${JSON.stringify(
    state
  )}}`;
  try {
    const response = await fetch(`${apiUrl}api/nft/get_nft_purchase_records`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loginToken()}`,
        "Content-Type": "application/json",
      },
      body: formData,
    });
    if (response.status === 200) {
      const jsonData = await response.json();
      if (jsonData.hasOwnProperty("log")) {
        throw jsonData.log;
      } else {
        return jsonData;
      }
    }
  } catch (err) {
    console.error(`ERROR ${err}`);
  }
};

// Process the NFT purchase request.
export const processNftPurchaseRequest = async (purchaseID, proof, state) => {
  const formData = `{"purchaseID":${JSON.stringify(
    purchaseID
  )},"proof":${JSON.stringify(proof)},"state":${JSON.stringify(state)}}`;
  try {
    const response = await fetch(`${apiUrl}api/nft/process_purchase`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loginToken()}`,
        "Content-Type": "application/json",
      },
      body: formData,
    });
    if (response.status === 200) {
      const jsonData = await response.json();
      if (jsonData.hasOwnProperty("log")) {
        throw jsonData.log;
      } else {
        return jsonData;
      }
    }
  } catch (err) {
    console.error(`ERROR ${err}`);
  }
};
