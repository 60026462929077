import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { GlobalContext } from "../../contexts/GlobalContext";
import { loginToken } from "../../utils/apiUtils";
import { GLOBAL_ROUTES } from "../../utils/routesUtil";

const PrivateLayout = ({ children }) => {
  const { state } = useContext(GlobalContext);
  const { userProfile } = state;

  let navigate = useNavigate();

  const token = loginToken();

  useEffect(() => {
    if (token === null) {
      navigate(GLOBAL_ROUTES.get("HOME").PATH, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return userProfile && children;
};

export default PrivateLayout;
