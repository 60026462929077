import { theme } from "../../../../utils/theme";

const selectMenu = {
  alignItems: "flex-start",
  borderColor: theme.palette.grays[300],
  borderRadius: 2,
  borderStyle: "solid",
  borderWidth: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  minWidth: 160,
  paddingBottom: 3,
  paddingLeft: 4,
  paddingRight: 4,
  paddingTop: 3,
  position: "absolute",
  zIndex: 3,
};

const selectOptionLabel = {
  color: theme.palette.grays[700],
  fontSize: theme.typography.fontSize * 0.88,
  whiteSpace: "nowrap",
};

export const styles = {
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  margin: {
    display: "flex",
    margin: 1,
  },
  selectContainer: {
    display: "flex",
    position: "relative",
    width: "fit-content",
  },
  selectFormContainer: {
    alignItems: "center",
    borderColor: theme.palette.grays[300],
    borderRadius: "50%",
    borderStyle: "solid",
    borderWidth: 0,
    cursor: "pointer",
    display: "flex",
    justifyContent: "flex-start",
    ml: 1,
    mr: 1,
    pb: 2,
    pl: 2,
    pr: 2,
    pt: 2,
    transition: "all 0.5s ease-in-out",
  },
  selectFormIcon: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  selectFormLabel: {
    color: theme.palette.secondary.main,
    fontSize: theme.typography.fontSize * 0.88,
    fontWeight: 500,
    letterSpacing: 1.1,
    lineHeight: 1,
    mr: 1,
  },
  selectFormLabelContainer: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    ml: 2,
  },
  selectMenuAnchorBottom: {
    ...selectMenu,
    "&::before": {
      content: "' '",
      backgroundColor: theme.palette.grays["white"],
      borderBottom: `1px solid ${theme.palette.grays[400]}`,
      borderLeft: `0px solid ${theme.palette.grays[400]}`,
      borderRight: `1px solid ${theme.palette.grays[400]}`,
      borderTop: `0px solid ${theme.palette.grays[400]}`,
      height: 10,
      position: "absolute",
      left: 32,
      bottom: -6,
      transform: "rotate(45deg)",
      width: 10,
    },
  },
  selectMenuAnchorTop: {
    ...selectMenu,
    "&::before": {
      content: "' '",
      backgroundColor: theme.palette.grays["white"],
      borderBottom: `0px solid ${theme.palette.grays[300]}`,
      borderLeft: `1px solid ${theme.palette.grays[300]}`,
      borderRight: `0px solid ${theme.palette.grays[300]}`,
      borderTop: `1px solid ${theme.palette.grays[300]}`,
      height: 10,
      position: "absolute",
      right: 16,
      top: -6,
      transform: "rotate(45deg)",
      width: 10,
    },
  },
  selectOption: {
    alignItems: "center",
    color: theme.palette.secondary.main,
    cursor: "pointer",
    display: "flex",
    justifyContent: "flex-start",
    paddingBottom: 2,
    paddingTop: 2,
    transition: "all 0.3s ease-in-out",
    width: "100%",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  selectOptionIcon: {
    color: theme.palette.primary.main,
  },
  selectOptionIconContainer: {
    alignItems: "center",
    display: "flex",
    height: 24,
    width: 24,
  },
  selectOptionLabelInactive: {
    ...selectOptionLabel,
    fontWeight: 400,
  },
  selectOptionLabelActive: {
    ...selectOptionLabel,
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
};
