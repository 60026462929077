import { theme } from "../../../../utils/theme";

export const styles = {
  actionButton: {
    minHeight: 36,
    minWidth: 140,
  },
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  margin: {
    display: "flex",
    margin: 1,
  },
  selectContainer: {
    display: "flex",
    position: "relative",
  },
  selectFormIcon: {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    justifyContent: "flex-start",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      color: theme.palette.grays[400],
    },
  },
  selectMenu: {
    alignItems: "flex-start",
    borderColor: theme.palette.grays[300],
    borderRadius: 2,
    borderStyle: "solid",
    borderWidth: 1,
    boxShadow:
      "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingBottom: 3,
    pb: 1,
    pl: 3,
    pr: 3,
    pt: 3,
    position: "absolute",
    right: 0,
    top: 62,
    zIndex: 3,
    "&::before": {
      content: "' '",
      position: "absolute",
      borderBottom: `8px solid ${theme.palette.grays.white}`,
      borderLeft: `8px solid transparent`,
      borderRight: `8px solid transparent`,
      height: 8,
      right: 20,
      top: -8,
      width: 8,
    },
  },
  selectOption: {
    alignItems: "center",
    backgroundColor: theme.palette.grays[100],
    borderColor: theme.palette.grays[300],
    borderRadius: 2,
    borderStyle: "solid",
    borderWidth: 1,
    color: theme.palette.secondary.main,
    cursor: "pointer",
    display: "flex",
    fontSize: theme.typography.fontSize * 0.88,
    fontWeight: 600,
    justifyContent: "flex-start",
    mb: 2,
    pb: 3,
    pl: 3,
    pr: 3,
    pt: 3,
    transition: "all 0.3s ease-in-out",
    width: 240,
    "&:hover": {
      backgroundColor: theme.palette.grays[200],
      color: theme.palette.primary.main,
    },
  },
  selectOptionImage: {
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    borderRadius: 1,
    height: 32,
    mr: 3,
    width: 32,
  },
  selectOptionLabel: {
    fontSize: theme.typography.fontSize * 0.88,
    marginRight: 2,
    whiteSpace: "nowrap",
  },
  selectOptionLabelActive: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
};
