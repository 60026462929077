export const styles = {
  logo: {
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    display: "block",
    height: 36,
    width: 124,
  },
  logoContainer: {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
  },
  logoIcon: {
    display: { md: "none", sm: "block" },
    height: 36,
    width: 36,
  },
  margin: {
    display: "flex",
    margin: 1,
  },
};
