import { Snackbar } from "@mui/material";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { GlobalContext } from "../../../contexts/GlobalContext";

const Toast = ({ handleConfirm }) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { showToast } = state;

  const { t } = useTranslation();

  const handleCloseSnackbar = () => {
    dispatch({
      type: "SHOW_TOAST",
      payload: {
        type: "undefined",
        message: undefined,
        isOpen: false,
      },
    });
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      autoHideDuration={3000}
      message={t(showToast.message)}
      onClose={handleCloseSnackbar}
      open={showToast.isOpen}
    />
  );
};

export default Toast;
