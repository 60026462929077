import { Box } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

import logo from "../../../assets/logo/logo.svg";
import logoWhite from "../../../assets/logo/logo_white.svg";
import { GLOBAL_ROUTES } from "../../../utils/routesUtil";
import { styles } from "./style";

const Logo = ({ dumb, lightTheme, white }) => {
  return (
    <Link to={GLOBAL_ROUTES.get("HOME").PATH}>
      <Box sx={styles.logoContainer}>
        {dumb ? (
          <Box
            component="div"
            sx={styles.logo}
            style={{
              backgroundImage: `url(${logo})`,
            }}
          />
        ) : (
          <Box
            component="div"
            sx={styles.logo}
            style={{
              backgroundImage: white ? `url(${logoWhite})` : `url(${logo})`,
            }}
          />
        )}
      </Box>
    </Link>
  );
};

export default Logo;
