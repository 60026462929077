import { appBar, theme } from "../../../../utils/theme";

const navText = {
  alignItems: "center",
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  fontSize: theme.typography.fontSize * 0.88,
  height: appBar.height,
  justifyContent: "center",
  letterSpacing: 1.1,
  pl: 1,
  pr: 1,
  transition: "all 0.5s ease-in-out",
  width: "fit-content",
};

export const styles = {
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  margin: {
    display: "flex",
    margin: 1,
  },
  navIcon: {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    fontSize: theme.typography.fontSize * 1.5,
    fontWeight: 400,
    height: appBar.height,
    justifyContent: "center",
    letterSpacing: 0.5,
    ml: 3,
    mr: 3,
    transition: "all 0.5s ease-in-out",
    width: "fit-content",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  navListContainer: {
    alignItems: "center",
    display: { xs: "none", sm: "flex" },
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    ml: 6,
  },
  navLink: {
    color: "inherit",
    textDecoration: "none",
  },
  navTextDefault: {
    ...navText,
    fontWeight: 400,
  },
  navTextActive: {
    ...navText,
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
};
