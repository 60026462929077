import { Box, ClickAwayListener, Paper, Typography } from "@mui/material";
import i18n from "i18next";
import React, { useState } from "react";
import { RiArrowDownSFill, RiArrowUpSFill, RiGlobalLine } from "react-icons/ri";

import { LANGUAGE_KEY } from "../../../../utils/apiUtils";
import { LANGUAGES_OPTIONS } from "../../../../utils/optionUtils";

import { styles } from "./styles";

const NavLanguageSelect = ({ horizontal, showValue, vertical }) => {
  const [languageAnchorEl, setLanguageAnchorEl] = useState(false);

  const handleClickSelect = () => {
    setLanguageAnchorEl(!languageAnchorEl);
  };

  const handleCloseLanguageMenu = () => {
    setLanguageAnchorEl(false);
  };

  const handleClickLanguage = (lng) => (evt) => {
    i18n.changeLanguage(lng);
    localStorage.setItem(LANGUAGE_KEY, lng);

    handleCloseLanguageMenu();
  };

  return (
    <ClickAwayListener onClickAway={handleCloseLanguageMenu}>
      <Box sx={styles.selectContainer}>
        <Box sx={styles.selectFormContainer} onClick={handleClickSelect}>
          <Box sx={styles.selectFormIcon}>
            <RiGlobalLine size={"1.3em"} />
          </Box>

          {showValue && (
            <>
              <Box sx={styles.selectFormLabelContainer}>
                <Typography sx={styles.selectFormLabel}>
                  {
                    Array.from(LANGUAGES_OPTIONS.values()).find(
                      (value) => value.KEY === i18n.language
                    ).LABEL
                  }
                </Typography>
              </Box>

              <Box sx={styles.grow} />

              {languageAnchorEl ? (
                <RiArrowUpSFill size="1.2em" style={styles.selectFormArrow} />
              ) : (
                <RiArrowDownSFill size="1.2em" style={styles.selectFormArrow} />
              )}
            </>
          )}
        </Box>

        {languageAnchorEl && (
          <Paper
            elevation={3}
            style={{ ...horizontal, ...vertical }}
            sx={
              Object.keys(vertical).includes("top")
                ? styles.selectMenuAnchorTop
                : styles.selectMenuAnchorBottom
            }
          >
            {Array.from(LANGUAGES_OPTIONS.keys()).map((option, index) => (
              <Box
                key={index}
                onClick={handleClickLanguage(LANGUAGES_OPTIONS.get(option).KEY)}
                sx={styles.selectOption}
              >
                <Typography
                  sx={
                    i18n.language === LANGUAGES_OPTIONS.get(option).KEY
                      ? styles.selectOptionLabelActive
                      : styles.selectOptionLabelInactive
                  }
                >
                  {LANGUAGES_OPTIONS.get(option).LABEL}
                </Typography>
              </Box>
            ))}
          </Paper>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default NavLanguageSelect;
