export const styles = {
  layoutContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "flex-start",
    width: "100%",
  },
  text: {
    fontSize: "1.1rem",
    fontWeight: 500,
    margin: "24px 0 4px 0 ",
  },
};
