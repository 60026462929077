import React, { lazy, Suspense } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Route, Routes } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import LoadingScreen from "./components/common/LoadingScreen";
import GlobalLayout from "./layouts/GlobalLayout";
import PrivateLayout from "./layouts/PrivateLayout";

import {
  GLOBAL_ROUTES,
  PRIVATE_ROUTES,
  PUBLIC_ROUTES,
} from "./utils/routesUtil";
import ConfirmDialog from "./components/common/ConfirmDialog";
import NavbarStatic from "./components/common/NavComponents/NavbarStatic";
import Toast from "./components/common/Toast";

const AccountManagementPage = lazy(() =>
  import("./pages/AccountManagementPage")
);
const DeleteSuccessPage = lazy(() => import("./pages/DeleteSuccessPage"));
const EditorPage = lazy(() => import("./pages/EditorPage"));
const HomePage = lazy(() => import("./pages/HomePage"));
const InstitutePage = lazy(() => import("./pages/InstitutePage"));
const InstituteListPage = lazy(() => import("./pages/InstituteListPage"));
const LikedPostsPage = lazy(() => import("./pages/LikedPostsPage"));
const LoginPage = lazy(() => import("./pages/LoginPage"));
const MyPostPage = lazy(() => import("./pages/MyPostPage"));
const PageNotFoundPage = lazy(() => import("./pages/PageNotFoundPage"));
const ProjectDetailsPage = lazy(() => import("./pages/ProjectDetailsPage"));
const ResetPasswordPage = lazy(() => import("./pages/ResetPasswordPage"));
const SearchPage = lazy(() => import("./pages/SearchPage"));
const SignupPage = lazy(() => import("./pages/SignupPage"));
const SubmitSuccessPage = lazy(() => import("./pages/SubmitSuccessPage"));
const UserManagementPage = lazy(() => import("./pages/UserManagementPage"));
const UserNotificationPage = lazy(() => import("./pages/UserNotificationPage"));
const DashboardPage = lazy(() => import("./pages/DashboardPage"));
const DashboardPageWithDummyData = lazy(() =>
  import("./pages/DashboardPageWithDummyData")
);
const ChatbotPage = lazy(() => import("./pages/ChatbotPage"));
const ChatbotTempPage = lazy(() => import("./pages/ChatbotTemporaryPage"));

/** NFT Workflow Page */
const ConnectWalletPage = lazy(() =>
  import("./pages/NftPage/ConnectWalletPage")
);
const CreateNftPage = lazy(() => import("./pages/NftPage/NftCreationPage"));
const MarketplacePage = lazy(() =>
  import("./pages/NftPage/NftMarketplacePage")
);
const MyNftPage = lazy(() => import("./pages/NftPage/MyNftPage"));
const NftDetailsPage = lazy(() => import("./pages/NftPage/NftDetailsPage"));
const ListNftPage = lazy(() => import("./pages/NftPage/NftListPage"));
const ApiTestPage = lazy(() => import("./nfts/APITest"));

/** Patent Pool Workflow Page */
const PatentPoolCreationPage = lazy(() =>
  import("./pages/PatentPoolPage/PatentPoolCreationPage")
);
const MyPatentPoolPage = lazy(() =>
  import("./pages/PatentPoolPage/MyPatentPoolPage")
);
const PatentPoolMarketplacePage = lazy(() =>
  import("./pages/PatentPoolPage/PatentPoolMarketplacePage")
);
const PatentPoolDetailPage = lazy(() =>
  import("./pages/PatentPoolPage/PatentPoolDetailsPage")
);
const PatentPoolEditPage = lazy(() =>
  import("./pages/PatentPoolPage/PatentPoolEditPage")
);

/** Demand Portal Workflow Page */

const DemandPortalListPage = lazy(() =>
  import("./pages/DemandPortalPage/DemandPortalListPage")
);
const MyDemandListPage = lazy(() =>
  import("./pages/DemandPortalPage/MyDemandListPage")
);
const DemandEditPage = lazy(() =>
  import("./pages/DemandPortalPage/DemandEditPage")
);
const DemandDetailPage = lazy(() =>
  import("./pages/DemandPortalPage/DemandDetailPage")
);

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>
          IP8Value: Intellectual Asset Management for Technology Innovation &
          Commercialization
        </title>
      </Helmet>

      <Suspense fallback={<LoadingScreen />}>
        <ToastContainer
          pauseOnHover={false}
          closeButton={true}
          hideProgressBar={true}
          closeOnClick={false}
          position={toast.POSITION.TOP_RIGHT}
        />
        <Routes>
          <Route
            path={GLOBAL_ROUTES.get("HOME").PATH}
            element={
              <GlobalLayout>
                <HomePage />
              </GlobalLayout>
            }
          />

          <Route
            path={GLOBAL_ROUTES.get("SEARCH").PATH}
            element={
              <GlobalLayout>
                <NavbarStatic />
                <SearchPage />
              </GlobalLayout>
            }
          />

          <Route
            path={GLOBAL_ROUTES.get("SEARCH").PATHNAME}
            element={
              <GlobalLayout>
                <NavbarStatic />
                <SearchPage />
              </GlobalLayout>
            }
          />

          <Route
            path={GLOBAL_ROUTES.get("PROJECT").PATH}
            element={
              <GlobalLayout>
                <NavbarStatic />
                <ProjectDetailsPage />
              </GlobalLayout>
            }
          />

          <Route
            path={GLOBAL_ROUTES.get("INSTITUTE").PATH}
            element={
              <GlobalLayout>
                <NavbarStatic />
                <InstitutePage />
              </GlobalLayout>
            }
          />

          <Route
            path={GLOBAL_ROUTES.get("INSTITUTIONS").PATH}
            element={
              <GlobalLayout>
                <NavbarStatic />
                <InstituteListPage />
              </GlobalLayout>
            }
          />

          <Route
            path={GLOBAL_ROUTES.get("MARKETPLACE").PATHNAME}
            element={
              <GlobalLayout>
                <NavbarStatic />
                <MarketplacePage />
              </GlobalLayout>
            }
          />

          <Route
            path={GLOBAL_ROUTES.get("MARKETPLACE").PATH}
            element={
              <GlobalLayout>
                <NavbarStatic />
                <MarketplacePage />
              </GlobalLayout>
            }
          />

          <Route
            path={GLOBAL_ROUTES.get("NFT").PATH}
            element={
              <GlobalLayout>
                <NavbarStatic />
                <NftDetailsPage />
              </GlobalLayout>
            }
          />

          <Route
            path={GLOBAL_ROUTES.get("DELETE_SUCCESS").PATH}
            element={
              <GlobalLayout>
                <NavbarStatic />
                <PrivateLayout>
                  <DeleteSuccessPage />
                </PrivateLayout>
              </GlobalLayout>
            }
          />

          <Route
            path={GLOBAL_ROUTES.get("PAGE_NOT_FOUND").PATH}
            element={
              <GlobalLayout>
                <NavbarStatic />
                <PageNotFoundPage />
              </GlobalLayout>
            }
          />

          <Route
            path={GLOBAL_ROUTES.get("SUBMIT_SUCCESS").PATH}
            element={
              <GlobalLayout>
                <NavbarStatic />
                <PrivateLayout>
                  <SubmitSuccessPage />
                </PrivateLayout>
              </GlobalLayout>
            }
          />

          <Route
            path={PRIVATE_ROUTES.get("DASHBOARD").PATH}
            element={
              <GlobalLayout>
                <NavbarStatic />
                <DashboardPage />
              </GlobalLayout>
            }
          />

          <Route
            path={PRIVATE_ROUTES.get("DASHBOARD_DUMMY").PATH}
            element={
              <GlobalLayout>
                <NavbarStatic />
                <DashboardPageWithDummyData />
              </GlobalLayout>
            }
          />

          <Route
            path={GLOBAL_ROUTES.get("CHATBOT").PATH}
            element={
              <GlobalLayout>
                <NavbarStatic />
                <ChatbotPage />
              </GlobalLayout>
            }
          />

          <Route
            path={GLOBAL_ROUTES.get("CHATBOT_TEMP").PATH}
            element={
              <GlobalLayout>
                <NavbarStatic />
                <ChatbotTempPage />
              </GlobalLayout>
            }
          />

          <Route
            path={PUBLIC_ROUTES.get("LOGIN").PATH}
            element={<LoginPage />}
          />

          <Route
            path={PUBLIC_ROUTES.get("SIGNUP").PATH}
            element={<SignupPage />}
          />

          <Route
            path={PRIVATE_ROUTES.get("POSTS").PATH}
            element={
              <GlobalLayout>
                <NavbarStatic />
                <PrivateLayout>
                  <MyPostPage />
                </PrivateLayout>
              </GlobalLayout>
            }
          />

          <Route
            path={PRIVATE_ROUTES.get("LIKED_POSTS").PATH}
            element={
              <GlobalLayout>
                <NavbarStatic />
                <PrivateLayout>
                  <LikedPostsPage />
                </PrivateLayout>
              </GlobalLayout>
            }
          />

          <Route
            path={PRIVATE_ROUTES.get("USERS").PATH}
            element={
              <GlobalLayout>
                <NavbarStatic />
                <PrivateLayout>
                  <UserManagementPage />
                </PrivateLayout>
              </GlobalLayout>
            }
          />

          <Route
            path={PRIVATE_ROUTES.get("ACCOUNT").PATH}
            element={
              <GlobalLayout>
                <NavbarStatic />
                <PrivateLayout>
                  <AccountManagementPage />
                </PrivateLayout>
              </GlobalLayout>
            }
          />

          <Route
            path={PRIVATE_ROUTES.get("EDITOR").PATH}
            element={
              <GlobalLayout>
                <NavbarStatic />
                <PrivateLayout>
                  <EditorPage />
                </PrivateLayout>
              </GlobalLayout>
            }
          />

          <Route
            path={PRIVATE_ROUTES.get("UPDATE_EDITOR").PATH}
            element={
              <GlobalLayout>
                <NavbarStatic />
                <PrivateLayout>
                  <EditorPage />
                </PrivateLayout>
              </GlobalLayout>
            }
          />

          <Route
            path={GLOBAL_ROUTES.get("PATENT_POOL_MARKETPLACE").PATHNAME}
            element={
              <GlobalLayout>
                <NavbarStatic />
                <PatentPoolMarketplacePage />
              </GlobalLayout>
            }
          />

          <Route
            path={GLOBAL_ROUTES.get("PATENT_POOL_MARKETPLACE").PATH}
            element={
              <GlobalLayout>
                <NavbarStatic />
                <PatentPoolMarketplacePage />
              </GlobalLayout>
            }
          />

          <Route
            path={GLOBAL_ROUTES.get("MY_PATENT_POOL").PATH}
            element={
              <GlobalLayout>
                <NavbarStatic />
                <MyPatentPoolPage />
              </GlobalLayout>
            }
          />

          <Route
            path={PRIVATE_ROUTES.get("CONNECT_WALLET").PATH}
            element={
              <GlobalLayout>
                <NavbarStatic />
                <PrivateLayout>
                  <ConnectWalletPage />
                </PrivateLayout>
              </GlobalLayout>
            }
          />

          <Route
            path={PRIVATE_ROUTES.get("CONNECT_WALLET_FROM_PROJECT").PATH}
            element={
              <GlobalLayout>
                <NavbarStatic />
                <PrivateLayout>
                  <ConnectWalletPage />
                </PrivateLayout>
              </GlobalLayout>
            }
          />

          <Route
            path={PRIVATE_ROUTES.get("CREATE_NFT").PATH}
            element={
              <GlobalLayout>
                <NavbarStatic />
                <PrivateLayout>
                  <CreateNftPage />
                </PrivateLayout>
              </GlobalLayout>
            }
          />

          <Route
            path={PRIVATE_ROUTES.get("CREATE_NFT_FROM_PROJECT").PATH}
            element={
              <GlobalLayout>
                <NavbarStatic />
                <PrivateLayout>
                  <CreateNftPage />
                </PrivateLayout>
              </GlobalLayout>
            }
          />

          <Route
            path={PRIVATE_ROUTES.get("My_NFTs").PATH}
            element={
              <GlobalLayout>
                <NavbarStatic />
                <PrivateLayout>
                  <MyNftPage />
                </PrivateLayout>
              </GlobalLayout>
            }
          />

          <Route
            path={PRIVATE_ROUTES.get("LIST_NFT").PATH}
            element={
              <GlobalLayout>
                <NavbarStatic />
                <PrivateLayout>
                  <ListNftPage />
                </PrivateLayout>
              </GlobalLayout>
            }
          />

          <Route
            path={PRIVATE_ROUTES.get("API_TEST").PATH}
            element={
              <GlobalLayout>
                <NavbarStatic />
                <PrivateLayout>
                  <ApiTestPage />
                </PrivateLayout>
              </GlobalLayout>
            }
          />

          <Route
            path={PRIVATE_ROUTES.get("USER_NOTIFICATION").PATH}
            element={
              <GlobalLayout>
                <NavbarStatic />
                <PrivateLayout>
                  <UserNotificationPage />
                </PrivateLayout>
              </GlobalLayout>
            }
          />

          <Route
            path={PRIVATE_ROUTES.get("CREATE_PATENT_POOL").PATH}
            element={
              <GlobalLayout>
                <NavbarStatic />
                <PrivateLayout>
                  <PatentPoolCreationPage />
                </PrivateLayout>
              </GlobalLayout>
            }
          />

          <Route
            path={GLOBAL_ROUTES.get("PATENT_POOL").PATH}
            element={
              <GlobalLayout>
                <NavbarStatic />
                <PatentPoolDetailPage />
              </GlobalLayout>
            }
          />

          <Route
            path={PRIVATE_ROUTES.get("EDIT_PATENT_POOL").PATH}
            element={
              <GlobalLayout>
                <NavbarStatic />
                <PrivateLayout>
                  <PatentPoolEditPage />
                </PrivateLayout>
              </GlobalLayout>
            }
          />

          <Route
            path={PUBLIC_ROUTES.get("RESET_PASSWORD").PATH}
            element={<ResetPasswordPage />}
          />

          <Route
            path={GLOBAL_ROUTES.get("DEMAND_PORTAL").PATH}
            element={
              <GlobalLayout>
                <NavbarStatic />
                <DemandPortalListPage />
              </GlobalLayout>
            }
          />

          <Route
            path={PRIVATE_ROUTES.get("MY_DEMANDS").PATH}
            element={
              <GlobalLayout>
                <NavbarStatic />
                <PrivateLayout>
                  <MyDemandListPage />
                </PrivateLayout>
              </GlobalLayout>
            }
          />

          <Route
            path={PRIVATE_ROUTES.get("CREATE_DEMAND").PATH}
            element={
              <GlobalLayout>
                <NavbarStatic />
                <PrivateLayout>
                  <DemandEditPage />
                </PrivateLayout>
              </GlobalLayout>
            }
          />

          <Route
            path={PRIVATE_ROUTES.get("EDIT_DEMAND").PATH}
            element={
              <GlobalLayout>
                <NavbarStatic />
                <PrivateLayout>
                  <DemandEditPage />
                </PrivateLayout>
              </GlobalLayout>
            }
          />

          <Route
            path={GLOBAL_ROUTES.get("DEMAND_DETAIL").PATH}
            element={
              <GlobalLayout>
                <NavbarStatic />
                <DemandDetailPage />
              </GlobalLayout>
            }
          />
        </Routes>
      </Suspense>

      <Toast />
      <ConfirmDialog />
    </HelmetProvider>
  );
}

export default App;
