import {
  Avatar,
  Box,
  ClickAwayListener,
  Paper,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useConnectWallet } from "@web3-onboard/react";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

import { GlobalContext } from "../../../../contexts/GlobalContext";
import { logout } from "../../../../utils/apiUtils";
import { PRIVATE_ROUTES } from "../../../../utils/routesUtil";
import { styles } from "./styles";
import { theme } from "../../../../utils/theme";

const NavUserProfileSelect = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { t } = useTranslation();
  // eslint-disable-next-line no-empty-pattern
  const [{}, connect] = useConnectWallet();
  let navigate = useNavigate();
  const [isSelectMenuOpen, setIsSelectMenuOpen] = useState(false);

  const { navMenuOptions, userProfile } = state;

  // eslint-disable-next-line no-unused-vars
  const stringToColor = (string) => {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  };

  const composeInitial = (name) => {
    const stringArr = String(name).split(" ");
    const fullInitials = stringArr.map((text) => {
      return text[0];
    });
    const firstTwoChar = fullInitials.splice(0, 1);

    return {
      sx: {
        bgcolor: theme.palette.grays[500],
        // bgcolor: stringToColor(firstTwoChar.join("")),
        height: 34,
        textTransform: "uppercase",
        width: 34,
      },
      children: firstTwoChar,
    };
  };

  const handleClickMenuItem = (path) => (evt) => {
    handleCloseMenu();
    navigate(path);
  };

  const handleLogout = () => {
    handleCloseMenu();
    logout(dispatch);
  };

  const handleCloseMenu = () => {
    setIsSelectMenuOpen(false);
  };

  const accountTypeMapping = {
    admin: "admin",
    owner: "manager",
    inventor: "inventor",
    public: "public user",
  };

  return (
    <Box sx={styles.selectContainer}>
      <Box onClick={() => setIsSelectMenuOpen(true)} sx={styles.selectFormIcon}>
        {/* <BiUserCircle size={"1.4em"} /> */}
        <Avatar {...composeInitial(userProfile.full_name)} />
      </Box>

      {isSelectMenuOpen && (
        <ClickAwayListener onClickAway={handleCloseMenu}>
          <Paper sx={styles.menuContainer} elevation={3}>
            <Box sx={styles.menuHeader}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={styles.menuHeaderText}>
                  {userProfile.full_name}
                </Typography>
                <Box sx={styles.chip}>
                  <Typography sx={styles.chipText}>
                    {accountTypeMapping[userProfile.account_type] ||
                      userProfile.account_type}
                  </Typography>
                </Box>
              </Box>
              <Typography sx={styles.menuHeaderSubtext}>
                {userProfile.email}
              </Typography>
            </Box>

            <Box sx={styles.divider} />

            <Box sx={styles.menuItemsContainer}>
              {navMenuOptions.map((key) => (
                <Box
                  key={key}
                  sx={styles.menuItem}
                  onClick={
                    (key === "My_NFTs" || key === "MY_PATENT_POOL") &&
                    !window.ethereum
                      ? () => {
                          connect();
                        }
                      : handleClickMenuItem(PRIVATE_ROUTES.get(key).PATH)
                  }
                >
                  <Box sx={styles.menuItemIcon}>
                    {PRIVATE_ROUTES.get(key).ICON}
                  </Box>
                  {t(PRIVATE_ROUTES.get(key).LABEL)}
                </Box>
              ))}
            </Box>

            <Box sx={styles.divider} />

            <Box sx={styles.menuFooterContainer}>
              <Box sx={styles.menuItem} onClick={handleLogout}>
                <Box sx={styles.menuItemIcon}>
                  <RiLogoutBoxRLine size="1.2em" />
                </Box>
                {t("Logout")}
              </Box>
            </Box>
          </Paper>
        </ClickAwayListener>
      )}
    </Box>
  );
};

export default NavUserProfileSelect;
