import { apiUrl, loginToken, logout } from "../utils/apiUtils";

// Some collaborator request to publish the modification, send notification to owner
export const makePublishNotify = async (id, dispatch) => {
  try {
    const response = await fetch(
      `${apiUrl}api/notify/make_notify_publish?pid=${id}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${loginToken()}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();

      if (jsonData.hasOwnProperty("log")) {
        throw jsonData.log;
      } else {
        return { data: jsonData, status: "resolved" };
      }
    }
  } catch (err) {
    console.error(`ERROR ${err}`);

    if (err === "Token is expired") {
      logout(dispatch);
    }

    return { data: err, status: "rejected" };
  }
};

// Get the publish notification for a user
export const getPublishNotify = async (type, dispatch) => {
  try {
    const response = await fetch(
      `${apiUrl}api/notify/get_publish?from=0&size=10&all=${type}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loginToken()}`,
        },
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      return { data: jsonData, status: "resolved" };
    }
  } catch (err) {
    console.error(`ERROR ${err}`);
    if (err === "Token is expired") {
      logout(dispatch);
    }

    return { data: err, status: "rejected" };
  }
};

// Some user buy owner's NFT, send notification to the owner
export const makeBuyNftNotify = async (id, dispatch) => {
  try {
    const response = await fetch(
      `${apiUrl}api/notify/make_notify_buy_nft?pid=${id}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${loginToken()}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();

      if (jsonData.log === "") {
        return { data: jsonData, status: "resolved" };
      } else {
        throw jsonData.log;
      }
    }
  } catch (err) {
    console.error(`ERROR ${err}`);

    if (err === "Token is expired") {
      logout(dispatch);
    }

    return { data: err, status: "rejected" };
  }
};

// Get the purchase notification for a user
export const getNftNotify = async (dispatch) => {
  try {
    const response = await fetch(
      `${apiUrl}api/notify/get_nft?from=0&size=10&all=1`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loginToken()}`,
        },
      }
    );
    if (response.status === 200) {
      const jsonData = await response.json();
      return { data: jsonData, status: "resolved" };
    }
  } catch (err) {
    console.error(`ERROR ${err}`);
    if (err === "Token is expired") {
      logout(dispatch);
    }

    return { data: err, status: "rejected" };
  }
};

// Get the patent pool notification for a user
export const getPatentPoolNotify = async (dispatch) => {
  try {
    const response = await fetch(
      `${apiUrl}api/notify/get_pool?from=0&size=10&all=1`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loginToken()}`,
        },
      }
    );
    if (response.status === 200) {
      const jsonData = await response.json();
      return { data: jsonData, status: "resolved" };
    }
  } catch (err) {
    console.error(`ERROR ${err}`);
    if (err === "Token is expired") {
      logout(dispatch);
    }

    return { data: err, status: "rejected" };
  }
};

// Mark the notification of a user as read.
export const markNotifyAsRead = async (id, dispatch) => {
  try {
    const response = await fetch(`${apiUrl}api/notify/mark_as_read?nid=${id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loginToken()}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      const jsonData = await response.json();

      if (jsonData.log === "") {
        return { data: jsonData, status: "resolved" };
      } else {
        throw jsonData.log;
      }
    }
  } catch (err) {
    console.error(`ERROR ${err}`);

    if (err === "Token is expired") {
      logout(dispatch);
    }

    return { data: err, status: "rejected" };
  }
};

// Send an email from admin email.
export const sendEmailFromAdmin = async (data, dispatch) => {
  const arrayOfProcessedStrings = [];
  for (const [name, value] of Object.entries(data)) {
    if (name === "html") {
      arrayOfProcessedStrings.push(`"${name}": ${JSON.stringify(value)}`);
    } else {
      arrayOfProcessedStrings.push(`"${name}": "${value}"`);
    }
  }
  
  let formData = `{${arrayOfProcessedStrings.join(", ")}}`;
  try {
    const response = await fetch(`${apiUrl}api/notify/make_email`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loginToken()}`,
        "Content-Type": "application/json",
      },
      body: formData,
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return { data: jsonData, status: "resolved" };
      // const jsonData = await response.json();

      // if (jsonData.log === "") {
      //   return { data: jsonData, status: "resolved" };
      // } else {
      //   throw jsonData.log;
      // }
    }
  } catch (err) {
    console.error(`ERROR ${err}`);

    if (err === "Token is expired") {
      logout(dispatch);
    }

    return { data: err, status: "rejected" };
  }
};
