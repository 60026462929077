import { Box, Badge } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { RiNotification3Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { PRIVATE_ROUTES } from "../../../../utils/routesUtil";
import { styles } from "./styles";
import {
  getPublishNotify,
  getNftNotify,
  getPatentPoolNotify,
} from "./../../../../api/NotificationApi";
import { GlobalContext } from "../../../../contexts/GlobalContext";
import { toast } from "react-toastify";

const NavNotificationButton = () => {
  const { dispatch } = useContext(GlobalContext);
  const [hasUnreadNotification, setHasUnreadNotification] = useState(false);

  useEffect(() => {
    fetchPostNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPostNotification = async () => {
    try {
      await Promise.all([
        getPublishNotify(0, dispatch),
        getNftNotify(dispatch),
        getPatentPoolNotify(dispatch),
      ])
        .then((responses) => {
          responses.forEach((resp) => {
            if (resp.status === "resolved") {
              if (resp.data) {
                resp.data.forEach((notification) => {
                  if (!notification.is_visited) {
                    setHasUnreadNotification(true);
                  }
                });
              }
            }
          });
        })
        .catch((error) => {
          console.error("Error:", error);
          toast.error(error);
        });
    } catch (error) {
      console.error("All requests failed:", error);
      toast.error("All requests failed");
    }
  };

  let navigate = useNavigate();
  const handleClickLink = (path) => (evt) => {
    navigate(path);
  };

  return (
    <Box sx={styles.navIcon}>
      {!hasUnreadNotification ? (
        <RiNotification3Line
          size={"1em"}
          onClick={handleClickLink(
            PRIVATE_ROUTES.get("USER_NOTIFICATION").PATH
          )}
        />
      ) : (
        <Badge sx={styles.notificationBellWithBadge} variant="dot">
          <RiNotification3Line
            size={"1.1em"}
            onClick={handleClickLink(
              PRIVATE_ROUTES.get("USER_NOTIFICATION").PATH
            )}
          />
        </Badge>
      )}
    </Box>
  );
};

export default NavNotificationButton;
