import { theme } from "../../../../utils/theme";

export const styles = {
  divider: {
    backgroundColor: theme.palette.grays[300],
    height: "1px",
    width: "100%",
  },
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  margin: {
    display: "flex",
    margin: 1,
  },
  menuContainer: {
    alignItems: "flex-start",
    borderColor: theme.palette.grays[300],
    borderRadius: 2,
    borderStyle: "solid",
    borderWidth: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minWidth: 160,
    pb: 2,
    pt: 4,
    position: "absolute",
    right: 4,
    top: 44,
    zIndex: 3,
    "&::before": {
      backgroundColor: theme.palette.grays["white"],
      borderBottom: `0px solid ${theme.palette.grays[300]}`,
      borderLeft: `1px solid ${theme.palette.grays[300]}`,
      borderRight: `0px solid ${theme.palette.grays[300]}`,
      borderTop: `1px solid ${theme.palette.grays[300]}`,
      content: "' '",
      height: 10,
      position: "absolute",
      right: 18,
      top: -6,
      transform: "rotate(45deg)",
      width: 10,
    },
  },
  menuFooterContainer: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    pb: 0,
    pl: 4,
    pr: 4,
    pt: 0,
    width: "100%",
  },
  menuHeader: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    height: "fit-content",
    justifyContent: "flex-start",
    pb: 4,
    pl: 4,
    pr: 4,
    width: "fit-content",
  },
  menuHeaderText: {
    fontSize: theme.typography.fontSize * 1.13,
    fontWeight: 500,
  },
  menuHeaderSubtext: {
    color: theme.palette.accent.main,
    fontSize: theme.typography.fontSize * 0.88,
    fontWeight: 300,
  },
  menuItem: {
    alignItems: "center",
    color: theme.palette.secondary.main,
    cursor: "pointer",
    display: "flex",
    fontSize: theme.typography.fontSize * 0.88,
    fontWeight: 400,
    minWidth: 88,
    pb: 3,
    pt: 3,
    width: "100%",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  menuItemsContainer: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    pb: 3,
    pl: 4,
    pr: 4,
    pt: 3,
    width: "100%",
  },
  menuItemIcon: {
    alignItems: "center",
    display: "flex",
    mr: 3,
  },
  selectContainer: {
    display: "flex",
    position: "relative",
  },
  selectFormIcon: {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    justifyContent: "flex-start",
    ml: 3,
    mr: 3,
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  chip: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: 50,
    color: theme.palette.primary.main,
    width: "max-content",
    lineHeight: 0,
    ml: 1,
    pb: 0.7,
    pl: 2,
    pr: 2,
    pt: 0.8,
  },
  chipText: {
    fontSize: theme.typography.fontSize * 0.6,
    fontWeight: 600,
  },
};
