import { Box } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { retrieveUserProfile } from "../../api/UserApi";
import { GlobalContext } from "../../contexts/GlobalContext";
import {
  NAV_MENU_OPTIONS,
  NAV_MENU_OPTIONS_ADMIN,
  NAV_MENU_OPTIONS_PREMIUM,
} from "../../utils/optionUtils";

import { styles } from "./styles";

const GlobalLayout = ({ children }) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { userProfile } = state;

  let navigate = useNavigate();

  useEffect(() => {
    if (userProfile === undefined) {
      loadUserProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!userProfile) return;

    if (userProfile.account_type === "admin") {
      dispatch({
        type: "SET_NAV_MENU_OPTIONS",
        payload: NAV_MENU_OPTIONS_ADMIN,
      });
    } else if (
      userProfile.account_type === "owner" ||
      userProfile.account_type === "inventor"
    ) {
      dispatch({
        type: "SET_NAV_MENU_OPTIONS",
        payload: NAV_MENU_OPTIONS_PREMIUM,
      });
    } else {
      dispatch({
        type: "SET_NAV_MENU_OPTIONS",
        payload: NAV_MENU_OPTIONS,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile]);

  const loadUserProfile = async () => {
    await retrieveUserProfile(dispatch, navigate);
  };

  return <Box sx={styles.layoutContainer}>{children}</Box>;
};

export default GlobalLayout;
