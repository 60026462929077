import { Box, Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import NavNotificationButton from "../NavNotificationButton";
import NavLanguageSelect from "../NavLanguageSelect";
import NavProjectSelect from "../NavProjectSelect";
import NavUserProfileSelect from "../NavUserProfileSelect";
import NavWalletButton from "../NavWalletButton";

import { GlobalContext } from "../../../../contexts/GlobalContext";
import {
  NAV_OPTIONS_GLOBAL,
  NAV_OPTIONS_PUBLIC,
} from "../../../../utils/optionUtils";

import { styles } from "./styles";

const NavList = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { userProfile } = state;

  const location = useLocation();
  const { t } = useTranslation();

  const handleClickLink = () => {
    dispatch({
      type: "SET_TARGET_PAGE",
      payload: location.pathname,
    });
  };

  // eslint-disable-next-line no-unused-vars
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false,
      },
      "google_translate_element"
    );
  };

  // useEffect(() => {
  //   var addScript = document.createElement("script");
  //   addScript.setAttribute(
  //     "src",
  //     "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
  //   );
  //   document.body.appendChild(addScript);
  //   window.googleTranslateElementInit = googleTranslateElementInit;
  // }, []);

  return (
    <Box sx={styles.navListContainer}>
      <Stack direction="row" spacing={4}>
        {Array.from(NAV_OPTIONS_GLOBAL).map(([key, value]) => (
          <Link
            key={value.LABEL}
            to={value.PATHNAME}
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <Typography
              sx={
                location.pathname === value.PATHNAME
                  ? styles.navTextActive
                  : styles.navTextDefault
              }
            >
              {t(value.LABEL)}
            </Typography>
          </Link>
        ))}
      </Stack>

      <Box sx={styles.grow} />

      {/* {userProfile && (
        <>
          {["FAVOURITES"].map((key) => (
            <Typography
              key={key}
              sx={styles.navText}
              onClick={handleClickLink(PRIVATE_ROUTES.get(key).PATH)}
            >
              {PRIVATE_ROUTES.get(key).LABEL}
            </Typography>
          ))}

          <Box sx={styles.verticalDivider} />
        </>
      )} */}

      {/* <WalletButton /> */}

      <NavProjectSelect />

      <Box sx={styles.margin} />
      <Box sx={styles.margin} />

      {userProfile ? (
        <>
          {process.env.REACT_APP_HIDE_NFT_FEATURE === "false" && <NavWalletButton />}
          <NavNotificationButton />
          <NavLanguageSelect horizontal={{ right: 2 }} vertical={{ top: 44 }} />
          <NavUserProfileSelect />
        </>
      ) : (
        <Stack direction="row" spacing={4}>
          {Array.from(NAV_OPTIONS_PUBLIC).map(([key, value]) => (
            <Link
              key={value.LABEL}
              to={value.PATH}
              onClick={handleClickLink}
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <Typography sx={styles.navTextDefault}>
                {t(value.LABEL)}
              </Typography>
            </Link>
          ))}

          <NavLanguageSelect horizontal={{ right: 2 }} vertical={{ top: 44 }} />
        </Stack>
      )}

      <div id="google_translate_element"></div>
    </Box>
  );
};

export default NavList;
