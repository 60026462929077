import { theme } from "../../../../utils/theme";
export const styles = {
  navIcon: {
    alignItems: "center",
    borderRadius: "50%",
    cursor: "pointer",
    display: "flex",
    fontSize: theme.typography.fontSize * 1.5,
    fontWeight: 400,
    justifyContent: "center",
    letterSpacing: 0.5,
    ml: 1,
    pb: 2,
    pl: 2,
    pr: 2,
    pt: 2,
    mr: 1,
    transition: "all 0.5s ease-in-out",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255,0.3)",
    },
  },
  notificationBellWithBadge: {
    "& .MuiBadge-dot": {
      backgroundColor:"#EB5757",
    },
  },
};
