import CryptoConvert from "crypto-convert";
import { fromWei, toChecksumAddress, toWei } from "web3-utils";

export const convert = new CryptoConvert({
  cryptoInterval: 50000, //Crypto prices update interval in ms (default 5 seconds on Node.js & 15 seconds on Browsers)
  fiatInterval: 60 * 1e3 * 60, //Fiat prices update interval (default every 1 hour)
  calculateAverage: true, //Calculate the average crypto price from exchanges
  binance: true, //Use binance rates
  bitfinex: true, //Use bitfinex rates
  coinbase: true, //Use coinbase rates
  kraken: true, //Use kraken rates
});

/**
 * @function shortenAddress
 *
 * @param {String} address - Full ETH address
 * @param {Number} charsStart - Letters from start
 * @param {Number} charsEnd - Letters from end
 * @return {String} - Shortened address, ex: 0xAbC...XyZ
 */
export const shortenAddress = (address, charsStart = 4, charsEnd = 4) => {
  return `${address.substring(0, charsStart + 2)}...${address.substring(
    address.length - charsEnd,
    address.length
  )}`;
};

/**
 * @function shortenBalance
 *
 * @param {String} balance - Balance
 * @return {String} - Shortened balance, ex: 100.000000
 */
export const shortenBalance = (balance) => {
  return Number(balance).toFixed(6);
};

/**
 * @function numberWithCommas
 *
 * @param {number | string} num - Numeric value
 * @return {string} - Return number in position format
 */
export const numberWithCommas = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 * @function formatNumber
 *
 * @param {number|string} n - Number to be formatted
 * @param {number} d - Number to decimals to be parsed and displayed
 * @return {number} - Formatted number to be displayed on the UI
 */
export function formatNumber(n, d = 3) {
  return parseFloat(Number(n).toFixed(d));
}

/**
 * @function getEthFromWei
 *
 * @param {string} wei - Wei value to be converted
 * @param {string} unit - Unit of input value provided
 * @return {string} - Return ETH value
 */
export const getEthFromWei = (wei, unit = "ether") => fromWei(wei, unit);

/**
 * @function getWeiFromEth
 *
 * @param {string} eth - ETH value to be converted
 * @param {string} unit - Unit of input value provided
 * @return {string} - Return Wei value
 */
export const getWeiFromEth = (eth) => toWei(eth);

/**
 * @function getChecksumAddress
 *
 * @param {string} address - ETH address
 * @return {string} - Return checksummed address
 */
export const getChecksumAddress = (address) => toChecksumAddress(address);

/**
 * @function copyToClipBoard
 *
 * @param {string} textFieldRef - selected text field
 * @return {string} - Return selected text field
 */
export const copyToClipBoard = (textFieldRef) => {
  const textField = textFieldRef.current;
  textField.focus();
  textField.select();
  document.execCommand("copy");
};

/**
 * @function fetchWithTimeout
 *
 * @param {string} resource
 * @param {string} options
 * @return {string} -response
 */
export const fetchWithTimeout = async (resource, options) => {
  const { timeout = 8000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);

  return response;
};
