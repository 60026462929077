import React, { memo, useEffect, useState } from "react";
import { Box, Typography, ClickAwayListener, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  useConnectWallet,
  useWallets,
  useAccountCenter,
} from "@web3-onboard/react";
import {
  RiWallet3Line,
  RiLogoutBoxRLine,
  RiLinksFill,
  RiFileCopyLine,
} from "react-icons/ri";
import { updateWallet } from "../../../../api/NftApi";
import { styles } from "./styles";
import { shortenAddress } from "../../../../utils/nftUtils";

const NavWalletButton = () => {
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  const connectedWallets = useWallets();

  const updateAccountCenter = useAccountCenter();
  const { t } = useTranslation();

  const [walletAnchorEl, setWalletAnchorEl] = useState(false);
  const [copied, setCopied] = useState(false);

  const handleClickSelect = () => {
    setWalletAnchorEl(!walletAnchorEl);
  };

  const handleCloseWalletMenu = () => {
    setWalletAnchorEl(false);
  };

  useEffect(() => {
    if (!connectedWallets.length) return;

    const connectedWalletsLabelArray = connectedWallets.map(
      ({ label }) => label
    );
    window.localStorage.setItem(
      "connectedWallets",
      JSON.stringify(connectedWalletsLabelArray)
    );

    // Disable the account center
    updateAccountCenter({
      enabled: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedWallets, wallet]);

  // useEffect(() => {
  //   const previouslyConnectedWallets = JSON.parse(
  //     window.localStorage.getItem("connectedWallets")
  //   );
  //   if (previouslyConnectedWallets?.length) {
  //     const setWalletFromLocalStorage = async () => {
  //       await connect({
  //         autoSelect: previouslyConnectedWallets[0],
  //       });
  //     };
  //     setWalletFromLocalStorage();
  //   }
  // }, [connect]);

  const updateWalletAddress = async (address) => {
    await updateWallet(address);
  };

  const handleConnectClick = async () => {
    try {
      const result = await connect();
      if (result[0].accounts[0].address) {
        await updateWalletAddress(result[0].accounts[0].address);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onCopy = () => {
    setCopied(true);
    setInterval(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <Box sx={styles.navIcon}>
      {connecting ? (
        <RiLinksFill size={"1.1em"} style={{ ...styles.connectedWalletIcon }} />
      ) : wallet ? (
        <ClickAwayListener onClickAway={handleCloseWalletMenu}>
          <Box sx={styles.selectContainer}>
            <RiWallet3Line
              size={"1.1em"}
              onClick={handleClickSelect}
              style={{ ...styles.connectedWalletIcon }}
            />
            {walletAnchorEl && (
              <Paper
                elevation={3}
                style={{ left: -10, top: 40 }}
                sx={styles.selectMenuAnchorTop}
              >
                <Box>
                  <Typography sx={styles.walletAddressLabel}>
                    {shortenAddress(connectedWallets[0].accounts[0].address)}
                  </Typography>
                </Box>
                <CopyToClipboard
                  onCopy={onCopy}
                  text={connectedWallets[0].accounts[0].address}
                >
                  <Box sx={styles.selectOption}>
                    <RiFileCopyLine size="0.8em" />
                    <Typography sx={styles.selectOptionLabel}>
                      {copied ? "Copied!" : "Copy Wallet"}
                    </Typography>
                  </Box>
                </CopyToClipboard>
                <Box sx={styles.divider} />
                <Box
                  sx={styles.selectOption}
                  onClick={() => {
                    disconnect(wallet);
                    window.localStorage.removeItem("connectedWallets");
                    window.location.reload();
                  }}
                >
                  <RiLogoutBoxRLine size={"0.8em"} />
                  <Typography sx={styles.selectOptionLabel}>
                    {t("Disconnect Wallet")}
                  </Typography>
                </Box>
              </Paper>
            )}
          </Box>
        </ClickAwayListener>
      ) : (
        <RiWallet3Line size={"1.1em"} onClick={handleConnectClick} />
      )}
    </Box>
  );
};

export default memo(NavWalletButton);
