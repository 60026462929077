import { appBar, theme } from "../../../../utils/theme";

const selectMenu = {
  alignItems: "flex-start",
  borderColor: theme.palette.grays[300],
  borderRadius: 2,
  borderStyle: "solid",
  borderWidth: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  paddingBottom: 3,
  paddingLeft: 4,
  paddingRight: 4,
  paddingTop: 3,
  position: "absolute",
  zIndex: 3,
};

export const styles = {
  navIcon: {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    fontSize: theme.typography.fontSize * 1.5,
    fontWeight: 400,
    height: appBar.height,
    justifyContent: "center",
    letterSpacing: 0.5,
    ml: 3,
    mr: 3,
    transition: "all 0.5s ease-in-out",
    width: "fit-content",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  selectContainer: {
    display: "flex",
    position: "relative",
    width: "fit-content",
  },
  selectMenuAnchorTop: {
    ...selectMenu,
    "&::before": {
      content: "' '",
      position: "absolute",
      borderBottom: `8px solid ${theme.palette.grays.white}`,
      borderLeft: `8px solid transparent`,
      borderRight: `8px solid transparent`,
      height: 8,
      // right: 12,
      top: -8,
      width: 8,
    },
  },
  selectOption: {
    alignItems: "center",
    color: theme.palette.secondary.main,
    cursor: "pointer",
    display: "flex",
    justifyContent: "flex-start",
    paddingBottom: 3,
    paddingTop: 3,
    transition: "all 0.3s ease-in-out",
    width: "100%",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  selectOptionLabel: {
    fontSize: theme.typography.fontSize * 0.88,
    marginRight: 2,
    marginLeft: 2,
    whiteSpace: "nowrap",
  },
  selectOptionLabelActive: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  connectedWalletIcon:{
    color: theme.palette.primary.main,
  },
  walletAddressLabel:{
    fontSize: theme.typography.fontSize * 1,
    fontWeight: 700,
    whiteSpace: "nowrap",
  },
  divider: {
    backgroundColor: theme.palette.grays[300],
    height: "1px",
    width: "100%",
  },
};
