import { createTheme } from "@mui/material/styles";
import { blue, green, red } from "@mui/material/colors";

const FONT_SIZES = {
  h1: "3rem",
  h2: "2.25rem",
  h3: "1.5rem",
  h4: "1.13rem",
  h5: "1.08rem",
  body: "1rem",
  body2: "0.88rem",
  body3: "0.75rem",
  body4: "0.68rem",
};

const PALETTE = {
  background: {
    default: "#fff",
    light: "#F6FBFF",
    dark: "#0c121f",
  },
  primary: {
    main: blue.A400,
    light: blue[50],
    dark: "#004cbf",
    linearGradient: "linear-gradient(90deg, #7C1CFD -13.41%, #139AF6 74.6%)",
  },
  secondary: {
    main: "#0A0E22",
  },
  accent: {
    main: "#ADB0C7",
    1: "#0065ff",
    // 1: "#00D2FF"
  },
  success: {
    main: green.A400,
  },
  danger: {
    main: red.A400,
  },
  grays: {
    white: "#fff",
    100: "#f6f8fa",
    200: "#f0f2f5",
    300: "#e4e6ef",
    400: "#b6b6c4",
    500: "#a2a6b8",
    600: "#7e8299",
    700: "#5e6278",
    800: "#4f5058",
    900: "#404254",
    1000: "#121526",
    1100: "#07080f",
    black: "#000",
  },
};

export const theme = createTheme({
  spacing: 4,
  typography: {
    fontSize: 15,
    fontFamily: ['"Segoe UI"', "Inter", "Arial", "sans-serif"].join(","),
    ...FONT_SIZES,
    useNextVariants: true,
  },
  palette: PALETTE,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 50,
          fontSize: 13,
          fontWeight: 500,
          paddingBottom: 6,
          paddingLeft: 14,
          paddingRight: 14,
          paddingTop: 6,
          textTransform: "capitalize",
          "&.Mui-disabled": {
            border: `solid 1px ${PALETTE.grays[300]}`,
          },
        },
        contained: {
          boxShadow: "1px 1px 2px rgba(0, 0, 0, 0.08)",
          "&:hover": {
            boxShadow: "1px 1px 2px rgba(0, 0, 0, 0.08)",
          },
        },
        outlined: {
          backgroundColor: PALETTE.grays[100],
          border: `1px solid ${PALETTE.grays[300]}`,
          boxShadow: "1px 1px 2px rgba(0, 0, 0, 0.08)",
          "&:hover": {
            border: `1px solid ${PALETTE.grays[300]}`,
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: PALETTE.grays[300],
          borderStyle: "solid",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: "54px !important",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: PALETTE.grays[200],
          borderColor: PALETTE.grays[400],
          borderStyle: "solid",
          borderWidth: 1,
          color: "#050038",
          fontSize: 12,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          flex: 1,
          position: "relative",
          width: "100%",
        },
        input: {
          border: `solid 0px ${PALETTE.grays[300]}`,
          borderRadius: 4,
          boxShadow: "none",
          color: PALETTE.secondary.main,
          flex: 1,
          fontSize: "0.88rem",
          padding: "6px",
          paddingLeft: "12px",
          transition: "all 0.3s ease-in-out",
          width: "100%",
          "&:focus": {
            border: `solid 0px ${PALETTE.secondary.main}`,
          },
          "&:hover": {
            border: `solid 0px ${PALETTE.grays[500]}`,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          flex: 1,
          position: "relative",
          width: "100%",
        },
        notchedOutline: {
          borderColor: PALETTE.grays[300],
          transition: "all 0.3s ease-in-out",
        },
        input: {
          border: `solid 0px ${PALETTE.grays[300]}`,
          borderRadius: 4,
          boxShadow: "none",
          color: PALETTE.secondary.main,
          flex: 1,
          fontSize: "0.88rem",
          padding: "6px",
          paddingLeft: "12px",
          transition: "all 0.3s ease-in-out",
          width: "100%",
          "&:focus": {
            border: `solid 0px ${PALETTE.secondary.main}`,
          },
          "&:hover": {
            border: `solid 0px ${PALETTE.grays[500]}`,
          },
        },
      },
    },
  },
});

export const appBar = {
  height: 54,
  maxWidth: {
    xs: "92%",
    md: "95%",
    lg: 1296,
  },
  width: "100%",
};

export const badge = {
  fontSize: 11.25,
  height: 16,
  minWidth: 16,
  padding: 1,
};

export const bodyWidth = {
  maxHeight: 1024,
  maxWidth: {
    xs: "100%",
    // sm: 930,
    lg: 1296,
    // xl: 1450
  },
  width: "100%",
};

export const grid = {
  sm: {
    height: 200,
    imageHeight: "100%",
    width: 270,
  },
  md: {
    height: 290,
    imageHeight: "100%",
    width: 470,
  },
  lg: {
    height: 500,
    imageHeight: "100%",
    width: "100%",
  },
  height: 360,
  imageHeight: 180,
  width: 298,
};

export const homePage = {
  section: {
    root: {
      alignItems: "flex-start",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      maxWidth: bodyWidth.maxWidth,
      minWidth: { xs: "inherit", md: grid.sm.width * 2 * 1.09 },
      pb: { xs: 12, md: 4 },
      pl: 5,
      pr: { xs: 0, lg: 5 },
      pt: { xs: 12, md: 16 },
      position: "relative",
      width: "inherit",
    },
    header: {
      alignItems: { xs: "end", md: "end" },
      display: "flex",
      justifyContent: "start",
      width: "100%",
      zIndex: 1,
    },
    headerDecoration: {
      background: theme.palette.primary.linearGradient,
      height: 26,
      mr: { xs: 5, md: 5 },
      mt: 0.35,
      minWidth: 4,
    },
    headerLink: {
      alignItems: "center",
      // color: theme.palette.grays[700],
      display: "flex",
      fontSize: {
        xs: theme.typography.fontSize * 0.5,
        md: theme.typography.fontSize * 1.13,
      },
      fontWeight: 400,
      // minWidth: 108,
      textDecoration: "none",
      transition: "all 0.3s ease-in-out",
      // "&:hover": {
      //   color: theme.palette.secondary.main,
      // },
    },
    headerText: {
      color: theme.palette.secondary.main,
      fontSize: {
        xs: theme.typography.fontSize * 1.5,
        md: theme.typography.fontSize * 1.8,
      },
      fontWeight: 600,
      letterSpacing: 1.2,
      lineHeight: 1.1,
      textTransform: "none",
      width: "100%",
    },
    headerTextContainer: {
      display: "flex",
      flexDirection: "column",
    },
    headerSubtext: {
      color: theme.palette.grays[700],
      fontSize: {
        xs: theme.typography.fontSize * 0.75,
        md: theme.typography.fontSize * 0.88,
      },
      fontWeight: 400,
      letterSpacing: 1.1,
    },
  },
};

export const searchPage = {
  select: {
    root: {
      display: "flex",
      mr: { xs: 0, md: 3 },
      mb: { xs: 2, md: 0 },
      position: { xs: "unset", md: "relative" },
      width: { xs: "100%", md: "fit-content" },
    },
    formContainer: {
      alignItems: "center",
      borderColor: theme.palette.grays[300],
      borderRadius: 9,
      borderStyle: "solid",
      borderWidth: 1,
      cursor: "pointer",
      display: "flex",
      justifyContent: "flex-start",
      paddingBottom: 2,
      paddingLeft: 3,
      paddingRight: 3,
      paddingTop: 2,
      transition: "all 0.3s ease-in-out",
      width: { xs: "100%", md: "100%" },
      "&:hover": {
        borderColor: theme.palette.primary.main,
      },
    },
    formContainerActive: {
      borderColor: theme.palette.primary.main,
    },
    formIcon: {
      color: theme.palette.secondary.main,
    },
    formLabel: {
      color: theme.palette.secondary.main,
      fontSize: theme.typography.fontSize * 0.88,
      pl: 3,
      pr: 3,
    },
    menu: {
      maxHeight: "60vh",
      overflowY: "auto",
      pb: 4,
      pl: 3,
      pr: 3,
      pt: 4,
      width: "100%",
    },
    menuBackground: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: { xs: "block", md: "none" },
      height: "100vh",
      left: 0,
      position: "fixed",
      top: 0,
      width: "100vw",
      zIndex: 2,
    },
    menuContainer: {
      alignItems: "flex-start",
      borderColor: theme.palette.grays[300],
      borderRadius: 2,
      borderStyle: "solid",
      borderWidth: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      minWidth: { xs: "100%", md: 240 },
      position: "absolute",
      top: { xs: 0, md: 44 },
      zIndex: 3,
    },
    option: {
      alignItems: "start",
      color: theme.palette.secondary.main,
      cursor: "pointer",
      display: "flex",
      justifyContent: "flex-start",
      transition: "all 0.3s ease-in-out",
      width: "100%",
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
    optionContainer: {
      width: "100%",
    },
    optionIcon: {
      color: theme.palette.primary.main,
    },
    optionIconContainer: {
      alignItems: "center",
      display: "flex",
      height: 20,
      justifyContent: "center",
      width: 20,
    },
    optionLabel: {
      fontSize: theme.typography.fontSize * 0.88,
      fontWeight: 400,
      lineHeight: 1.1,
      whiteSpace: "break-spaces",
    },
    optionSublabel: {
      color: theme.palette.grays[500],
      fontSize: theme.typography.fontSize * 0.75,
      fontWeight: 400,
      lineHeight: 1.1,
      whiteSpace: "break-spaces",
    },
    optionLabelActive: {
      color: theme.palette.primary.main,
    },
  },
};

export const editorPage = {
  columnMain: {
    maxWidth: 900,
  },
  section: {
    width: "100%",
  },
  toolbox: {
    width: 170,
  },
};

export const projectDetailsPage = {
  carousel: {
    maxWidth: {
      xs: "100%",
      md: 900,
    },
  },
  columnMain: {
    width: { xs: "92%", md: "56%", lg: "100%" },
    maxWidth: 900,
  },
  coverImage: {
    maxHeight: 680,
  },
};

export const gridMarketplace = {
  minHeight: 240,
  width: 177,
};

export const modal = {
  headerHeight: 34,
  height: "90vh",
  maxHeight: 1200,
  paddingLeft: 4,
  paddingRight: 4,
  action: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    pb: 5,
    pl: 6,
    pr: 6,
    pt: 3,
    position: "relative",
    width: "100%",
  },
  body: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "center",
    pb: 3,
    pl: 8,
    pr: 8,
    pt: 0,
    width: "100%",
  },
  bodyText: {
    color: theme.palette.grays[700],
    fontSize: theme.typography.fontSize * 0.88,
    fontWeight: 400,
  },
  header: {
    display: "flex",
    height: 38,
    justifyContent: "flex-start",
    pl: 4,
    pr: 4,
    pt: 2,
    width: "100%",
  },
  headerIconButton: {
    alignItems: "center",
    color: theme.palette.grays[400],
    display: "flex",
    height: 36,
    width: 36,
  },
  headerText: {
    color: theme.palette.secondary.main,
    fontSize: theme.typography.fontSize * 1.13,
    fontWeight: 500,
    letterSpacing: 1.2,
    lineHeight: 1.1,
    textTransform: "capitalize",
  },
  root: {
    backgroundColor: theme.palette.grays.white,
    borderRadius: 2,
    color: theme.palette.secondary.main,
    minWidth: 360,
    overflowX: "hidden",
  },
};

export const nftListPage = {
  section: {
    width: 1296,
  },
  toolbox: {
    width: 240,
  },
};

export const patentPoolDetailsPage = {
  carousel: {
    maxWidth: {
      xs: "100%",
      md: 900,
    },
  },
  columnMain: {
    maxWidth: 900,
  },
  coverImage: {
    maxHeight: 680,
  },
};
