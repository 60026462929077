import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { InfinitySpin } from "react-loader-spinner";
import { theme } from "../../../utils/theme";
import { styles } from "./styles";

const Loader = () => {
  const { t } = useTranslation();

  return (
    <Box sx={styles.loaderContainer}>
      <InfinitySpin color={theme.palette.primary.main} width="188" />
      <Typography sx={styles.text}>{t("One moment please...")}</Typography>
    </Box>
  );
};

export default Loader;
