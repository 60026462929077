import { AppBar, Toolbar } from "@mui/material";

import Logo from "../../Logo";
import NavList from "../NavList";
import NavMenu from "../NavMenu";

import { styles } from "./styles";

const Navbar = () => {
  return (
    <AppBar position="sticky" elevation={0} sx={styles.nav}>
      <Toolbar sx={styles.toolbar}>
        <Logo dumb />

        <NavList />

        <NavMenu />
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
